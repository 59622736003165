import { createSlice } from "@reduxjs/toolkit";
import axios from "utils/axios";
import { ofType } from 'redux-observable';
import { switchMap, map } from 'rxjs/operators';

const initialState = {
  loading: true,
  data: null,
  error: null,
  rules: []
}

const promotionRulesSlice = createSlice({
  name: 'promotion-rules',
  initialState: initialState,
  reducers: {
    fetchPromotionRules(state, action: any) {
      // state.loading = true;
    },
    fetchedPromotionRules(state, action: any) {
      state.loading = false;
      state.data = action.payload.data;
      state.rules = action.payload.data;
    },
    updateValue(state, action: any) {
      let index = action.payload.index;
      let key = action.payload.key;
      let value = action.payload.value;
      state.rules[index][key] = value;
    },
    updatePromotionRules(state, action: any) {

    },
    updatedPromotionRules(state, action: any) {

    },

  }
});

export function fetchPromotionRulesEpic(action$, state$) {
  return action$.pipe(
    ofType(fetchPromotionRules.type),
    switchMap(async (action: any) => {
      const res = await axios.get(`/promotion-rules?curriculumId=${action.payload.curriculumId}`);
      return {
        data: res.data,
      }
    }),
    map(fetchedPromotionRules),
  );
}

export function updatePromotionRulesEpic(action$, state$) {
  return action$.pipe(
    ofType(updatePromotionRules.type),
    switchMap(async (action: any) => {
      const res = await axios.post(`/promotion-rules?curriculumId=${action.payload.curriculumId}`, action.payload.data);
      return {
        data: res.data,
      }
    }),
    map(fetchPromotionRules),
  );
}

export const {
  fetchPromotionRules,
  fetchedPromotionRules,
  updateValue,
  updatePromotionRules,
  updatedPromotionRules,
} = promotionRulesSlice.actions

export default promotionRulesSlice.reducer;