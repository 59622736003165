import {createSlice} from "@reduxjs/toolkit";
import {ofType} from 'redux-observable';
import {switchMap, map, filter} from 'rxjs/operators';
import axios from "utils/axios";

interface SubjectAttendanceState {
  data: any
  error: any
  loading: boolean
  absentStudents: Array<any>
  blockedStudents: Array<any>
  presentStudents: any[],
  malPractice: any[],
  courtCases: any[],
}

const initialState: SubjectAttendanceState = {
  data: [],
  error: null,
  loading: true,
  absentStudents: [],
  blockedStudents: [],
  presentStudents: [],
  malPractice: [],
  courtCases: [],
};


const subjectAttendanceSlice = createSlice({
  name: 'subject-attendance',
  initialState,
  reducers: {
    fetchSubjectAttendance(state, action) {
    },
    fetchedSubjectAttendance(state, action) {
      state.data = action.payload.data;
      state.absentStudents = action.payload.data.absentStudents;
      state.presentStudents = action.payload.data.presentStudents;
      state.malPractice = action.payload.data.malPractice;
      state.courtCases = action.payload.data.courtCases;
      state.loading = false;
      state.error = null;
    },
  }
});

export const {
  fetchSubjectAttendance,
  fetchedSubjectAttendance
} = subjectAttendanceSlice.actions

export function fetchSubjectAttendanceEpic(action$) {
  return action$.pipe(
    ofType(fetchSubjectAttendance.type),
    switchMap(async (action: any) => {
      try {
        let res = await axios.get(`/exams/attendance`, {
          params: action.payload.params
        });
        
        return {
          data: res.data,
        };
      } catch (err) {
        console.log(err);
        alert(`Error occurred: [${err.message}]`)
      }
    }),
    map(data => fetchedSubjectAttendance(data))
  );
}


export default subjectAttendanceSlice.reducer;
