import { createSlice } from "@reduxjs/toolkit";
import { ofType } from "redux-observable";
import { switchMap, map, filter } from "rxjs/operators";
import axios from "utils/axios";
import * as XLSX from "xlsx";
import moment from "moment";
import _ from "lodash";
interface RevaluationState {
  data: Array<any>;
  error: any;
  loading: boolean;
  groupBySubjectCode: any;
}

const initialState: RevaluationState = {
  data: [],
  error: null,
  loading: true,
  groupBySubjectCode: {},
};

function isNumber(n) {
  return !isNaN(parseFloat(n)) && !isNaN(n - 0);
}

const revaluationSlice = createSlice({
  name: "revaluation",
  initialState,
  reducers: {
    fetchRevaluationStudents(state, action) {},
    fetchingRevaluationStudents(state) {
      state.loading = true;
    },
    fetchedRevaluationStudents(state, action) {
      state.data = action.payload.data;
      state.loading = false;
      state.error = null;
      state.groupBySubjectCode = _.groupBy(action.payload.data, "subject_code");
    },
    updateMarks(state, action) {
      const index = action.payload.index;
      const key = action.payload.key;
      const marks = action.payload.marks;
      state.data[index][key] = marks;
    },
  },
});

export const {
  fetchRevaluationStudents,
  fetchedRevaluationStudents,
  updateMarks,
} = revaluationSlice.actions;

export function fetchRevaluationStudentsEpic(action$) {
  return action$.pipe(
    ofType(fetchRevaluationStudents.type),
    switchMap(async (action: any) => {
      try {
        let res = await axios.get(`/revaluation/subjects`, {
          params: {
            monthYear: action.payload.monthYear,
            semNo: action.payload.semNo,
            regulationId: action.payload.regulationId,
            courseId: action.payload.courseId,
          },
        });
        return {
          data: res.data,
        };
      } catch (err) {
        return {
          data: [],
        };
        // alert(`Error occurred: [${err.message}]`);
      }
    }),
    map((data) => fetchedRevaluationStudents(data))
  );
}

export default revaluationSlice.reducer;
