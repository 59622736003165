import { createSlice } from "@reduxjs/toolkit";
import axios from "utils/axios";
import { ofType } from 'redux-observable';
import { switchMap, map } from 'rxjs/operators';

const newData = [
  {
    name: 'First class with distinction',
    min: 8,
    max: 10,
    minOp: '<=',
    maxOp: '<=',
    isBacklog: true,
    isDetention: true
  },
  {
    name: 'First class',
    min: 6.5,
    max: 8,
    minOp: '<=',
    maxOp: '<',
    isBacklog: true,
    isDetention: true
  }, {
    name: 'Second class',
    min: 5.5,
    max: 6.5,
    minOp: '<=',
    maxOp: '<',
    isBacklog: true,
    isDetention: true
  },
  {
    name: 'Pass class',
    min: 5,
    max: 5.5,
    minOp: '<=',
    maxOp: '<',
    isBacklog: true,
    isDetention: true
  },
]

const initialState = {
  loading: true,
  data: null,
  error: null,
  rules: []
}

const awardOfClassSlice = createSlice({
  name: 'award-of-class',
  initialState: initialState,
  reducers: {
    fetchAwardOfClass(state, action: any) {
      // state.loading = true;
    },
    fetchedAwardOfClass(state, action: any) {
      state.loading = false;
      state.data = action.payload.data;
      state.rules = action.payload.data;
    },
    updateValue(state, action: any) {
      let index = action.payload.index;
      let key = action.payload.key;
      let value = action.payload.value;
      state.data[index][key] = value;
    },
    updateAwardOfClass(state, action: any) {

    },
    updatedAwardOfClass(state, action: any) {

    },

  }
});

export function fetchAwardOfClassEpic(action$, state$) {
  return action$.pipe(
    ofType(fetchAwardOfClass.type),
    switchMap(async (action: any) => {
      const res = await axios.get(`/promotion-rules?curriculumId=1`);
      return {
        data: newData,
      }
    }),
    map(fetchedAwardOfClass),
  );
}

export function updateAwardOfClassEpic(action$, state$) {
  return action$.pipe(
    ofType(updateAwardOfClass.type),
    switchMap(async (action: any) => {
      // const res = await axios.post(`/curriculums/${action.payload.curriculumId}/assessment-rules`, action.payload.data);
      return {
        data: newData,
      }
    }),
    map(updatedAwardOfClass),
  );
}

export const {
  fetchAwardOfClass,
  fetchedAwardOfClass,
  updateValue,
  updateAwardOfClass,
  updatedAwardOfClass,
} = awardOfClassSlice.actions

export default awardOfClassSlice.reducer;