import React from "react";
import romanize from "utils/romanize";

interface HeaderProps {
  logo: any;
  schoolName: string;
  address: string;
  title: string;
  branchCode: string;
  course: string;
  semester: any;
  monthYear: string;
  regulation: string;
}

export function Header(props: HeaderProps) {
  return (
    <header>
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <img src={props.logo} style={{ height: "2rem" }} />
            </div>
            <div style={{ marginLeft: "0.5rem", marginRight: "0.5rem" }}>
              <div style={{ fontSize: "20px" }}>
                <b>{props.schoolName}</b>
              </div>
            </div>
          </div>
          <div className="address">{props.address}</div>
        </div>
        <hr />
        <div className="address">
          <b>
            <u>{props.title}</u>
          </b>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: "8px",
            marginBottom: "16px",
          }}
        >
          <div>
            <b>Program: </b>
            {props.course} ({props.branchCode})
          </div>

          <div>
            <b>Examination: </b>
            {romanize(props.semester.year)} {props.course} {props.semester.name}{" "}
            REGULAR EXAMINATIONS ({props.regulation}),{props.monthYear}
          </div>
        </div>
      </div>
    </header>
  );
}
