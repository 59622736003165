import { createSlice } from "@reduxjs/toolkit";
import axios from "utils/axios";
import { ofType } from 'redux-observable';
import { switchMap, map } from 'rxjs/operators';
import moment from "moment";

const initialState = {
  loading: true,
  data: null,
  error: null,
  timeTable: []
}

const timeTableSlice = createSlice({
  name: 'time-table',
  initialState: initialState,
  reducers: {
    fetchTimeTable(state, action: any) {
      state.loading = true;
    },
    fetchedTimeTable(state, action: any) {
      state.loading = false;
      state.data = action.payload.data;
      state.timeTable = action.payload.data.timetable.map(item => ({
        ...item, date: item.date ? moment(item.date) : moment(new Date()),
        dateCopy: item.date,
      }));
    },
    updateValue(state, action: any) {
      let index = action.payload.index;
      let key = action.payload.key;
      let value = action.payload.value;
      state.timeTable[index][key] = value;
    },
  }
});

export function fetchTimeTableEpic(action$, state$) {
  return action$.pipe(
    ofType(fetchTimeTable.type),
    switchMap(async (action: any) => {
      const res = await axios.get(`/exams/${action.payload.examId}/timetable`);
      return {
        data: res.data,
      }
    }),
    map(fetchedTimeTable),
  );
}


export const { fetchTimeTable, fetchedTimeTable, updateValue } = timeTableSlice.actions

export default timeTableSlice.reducer;