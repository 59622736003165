import {createSlice} from "@reduxjs/toolkit";
import {ofType} from 'redux-observable';
import {switchMap, map, filter} from 'rxjs/operators';
import axios from "utils/axios";
import * as XLSX from 'xlsx';
import moment from 'moment';

interface UsersState {
  data: Array<any>
  error: any
  loading: boolean
  users: Array<any>
  filteredData: Array<any>
  
}

const initialState: UsersState = {
  data: [],
  error: null,
  loading: true,
  users: [],
  filteredData: [],
};

function isNumber(n) {
  return !isNaN(parseFloat(n)) && !isNaN(n - 0)
}

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    fetchUsers(state) {
    },
    fetchingUsers(state) {
      state.loading = true;
    },
    fetchedUsers(state, action) {
      state.data = action.payload.data;
      state.users = action.payload.data;
      state.loading = false;
      state.error = null;
    },
    onSearch(state, action) {
      let value = action.payload.value.toUpperCase();
      if (!value) {
        state.users = state.data;
        return;
      }
      state.users = state.data.filter(user => {
        if (user.fullName && `${user.fullName.toUpperCase() || ''}`.includes(value.toUpperCase())) {
          return true
        } else if (user.username &&`${user.username.toUpperCase() || ''}`.includes(value)) {
          return true
        } else if (user.email && `${user.email.toUpperCase() || ''}`.includes(value)) {
          return true
        }
      });
    },
  }
});

export const {
  fetchUsers,
  fetchedUsers,
  onSearch,
} = usersSlice.actions

export function fetchUserssEpic(action$) {
  return action$.pipe(
    ofType(fetchUsers.type),
    switchMap(async (action: any) => {
      try {
        let res = await axios.get(`/users`);
        
        return {
          data: res.data
        };
      } catch (err) {
        console.log(err);
        alert(`Error occurred: [${err.message}]`)
      }
    }),
    map(data => fetchedUsers(data))
  );
}


export default usersSlice.reducer;
