import {createSlice} from "@reduxjs/toolkit";
import axios from "utils/axios";
import {ofType} from 'redux-observable';
import {switchMap, map} from 'rxjs/operators';

const initialState = {
  loading: true,
  data: [],
  examSessions: {},
  error: null,
}

const examSessionsSlice = createSlice({
  name: 'examSessionsSlice',
  initialState: initialState,
  reducers: {
    fetchExamSessions(state, action: any) {
      state.loading = true;
    },
    fetchedExamSessions(state, action: any) {
      state.loading = false;
      state.data = action.payload.data.exams;
      // state.examSessions = action.payload.data.map(item => ({
      //   ...item,
      //   isEditing: false
      // }));
    }
  }
});

export function fetchExamSessionsEpic(action$, state$) {
  return action$.pipe(
    ofType(fetchExamSessions.type),
    switchMap(async (action: any) => {
      const res = await axios.get(`/exam-sessions/exam-sessions`);
      return {
        data: res.data,
      }
    }),
    map(fetchedExamSessions),
  );
}

const {fetchedExamSessions} = examSessionsSlice.actions;

export const {
  fetchExamSessions
} = examSessionsSlice.actions
export default examSessionsSlice.reducer;