import { createSlice } from "@reduxjs/toolkit";
import axios from "utils/axios";
import { ofType } from "redux-observable";
import { switchMap, map } from "rxjs/operators";

const initialState = {
  data: null,
  page: null,
  limit: null,
  error: null,
  loading: false,
  items: [],
  // links: {},
  // meta: {},
};

const postsSlice = createSlice({
  name: "posts",
  initialState: initialState,
  reducers: {
    fetchPosts(state, action: any) {
      state.loading = true;
      state.limit = action.payload.limit;
      state.page = action.payload.page
    },
    fetchedPosts(state, action: any) {
      state.data = action.payload.data;
      state.items = action.payload.data;
      // state.links = action.payload.data.links;
      // state.meta = action.payload.data.meta;
      state.loading = false;
    },
    fetchMorePosts(state) {
      state.loading = true;
      state.page = state.page + 1

    },
    newPostsFetched(state, action: any) {
      state.loading = false;
      if (action?.payload?.data) {
        state.data = action.payload.data;
        state.items = [...state.items, ...action.payload.data];
        // state.links = action.payload.data.links;
        // state.meta = action.payload.data.meta;
      }
    },
  },
});

export function fetchPostsEpic(action$, state$) {
  return action$.pipe(
    ofType(fetchPosts.type),
    switchMap(async (action: any) => {
      const res = await axios.get("/posts", {
        params: {
          page: action.payload.page,
          limit: action.payload.limit,
        },
      });
      return {
        data: res.data,
      };
    }),
    map(fetchedPosts)
  );
}

export function fetchMorePostsEpic(action$, state$) {
  return action$.pipe(
    ofType(fetchMorePosts.type),
    switchMap(async (action: any) => {
      if (state$.value.posts.data) {
        const res = await axios.get("/posts", {
          params: {
            page: state$.value.posts.page,
            limit: state$.value.posts.limit,
          },
        });
        return {
          data: res.data,
        };
      }
    }),
    map(newPostsFetched)
  );
}

export const { fetchPosts, fetchedPosts, fetchMorePosts, newPostsFetched } =
  postsSlice.actions;

export default postsSlice.reducer;
