import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  List,
  Row,
  Select,
  Space,
} from "antd";
import Spinner from "components/Spinner";
import CustomBreadcrumb from "components/TheLayout/CustomBreadcrumb";
import { Store } from "pullstate";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSubjects,
  onSearch,
  selectSubject,
} from "redux/slices/batchSubjectsSlice";
import styled from "styled-components";
import { batchOptions } from "../../constants/UIConstants";
import useFetch from "../../hooks/useFetch";
import ExternalMarks from "./ExternalMarks";
import MarksTabs from "./MarksTabs";

const { Search } = Input;
const { Option } = Select;

const StyledListItem = styled.div<{ selected: boolean }>`
  ${(props) => (props.selected ? `border-right: 4px solid #C02632` : "")};

  ${(props) => (props.selected ? ` background-color: #F8F8F8;` : "")}
  .ant-list-item-meta-title {
    ${(props) => (props.selected ? `color: #C02632` : "")}
  }
`;

const StyledList = styled.div`
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  .header {
    padding: 3px;
    overflow: hidden;
  }
  .list {
    ::-webkit-scrollbar {
      display: none;
    }
    cursor: pointer;
    width: 100%;
    height: 700px;
    overflow-y: scroll;
    padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
    box-sizing: content-box;
  }
`;

export const store = new Store({
  filterOptions: {
    regulations: [],
    courses: [],
    programs: [],
    semesters: [],
    subjectTypes: [],
  },
  filter: {
    regulationId: null,
    courseId: null,
    branchCode: null,
    semNo: null,
    batch: null,
  },
});

function CurriculumSubjects() {
  const dispatch = useDispatch();
  const { loading: loadingFilter, data: filterData } =
    useFetch("/subjects/filter");
  const state = store.useState();

  if (loadingFilter) {
    return <Spinner />;
  }

  return (
    <>
      <Row justify={"space-between"}>
        <Col style={{ display: "flex" }}>
          <Row justify={"center"}>
            <Col>
              <CustomBreadcrumb links={[{ name: "Batches", path: null }]} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <div>
            <Space size={24}>
              <Form.Item label="Regulation">
                <Select
                  value={state.filter.regulationId}
                  style={{ minWidth: "8rem" }}
                  onChange={(val) =>
                    store.update((s) => {
                      s.filter.regulationId = val;
                    })
                  }
                >
                  <Option value={null}>None</Option>
                  {filterData.regulations.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Course">
                <Select
                  value={state.filter.courseId}
                  style={{ minWidth: "8rem" }}
                  onChange={(val) =>
                    store.update((s) => {
                      s.filter.courseId = val;
                      s.filter.branchCode = null;
                      s.filter.semNo = null;
                    })
                  }
                >
                  <Option value={null}>None</Option>
                  {filterData.courses.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.courseName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Program">
                <Select
                  value={state.filter.branchCode}
                  style={{ minWidth: "8rem" }}
                  onChange={(val) =>
                    store.update((s) => {
                      s.filter.branchCode = val;
                    })
                  }
                >
                  <Option value={null}>None</Option>
                  {filterData.programs
                    .filter((item) => item.courseId == state.filter.courseId)
                    .map((item) => (
                      <Option key={item.id} value={item.branchCode}>
                        {item.branchCode}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item label="Sem No">
                <Select
                  value={state.filter.semNo}
                  style={{ minWidth: "8rem" }}
                  onChange={(val) =>
                    store.update((s) => {
                      s.filter.semNo = val;
                    })
                  }
                >
                  <Option value={null}>None</Option>
                  {filterData.semesters
                    .filter((item) => item.courseId == state.filter.courseId)
                    .map((item) => (
                      <Option key={item.id} value={item.semNo}>
                        {item.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item label="Batch">
                <Select
                  value={state.filter.batch}
                  style={{ minWidth: "8rem" }}
                  onChange={(val) =>
                    store.update((s) => {
                      s.filter.batch = val;
                    })
                  }
                >
                  <Option value={null}>None</Option>
                  {batchOptions.map((item, index) => (
                    <Option key={index} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  onClick={(e) =>
                    dispatch(
                      fetchSubjects({
                        ...state.filter,
                      })
                    )
                  }
                >
                  Search
                </Button>
              </Form.Item>
            </Space>
          </div>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ paddingTop: 24 }}>
        {/* <Col xs={24}>{<ExternalMarks />}</Col> */}
        <Col xs={8}>
          <Subjects />
        </Col>
        <Col xs={16}>
          <MarksTabs />
        </Col>
      </Row>
    </>
  );
}

function Subjects() {
  const batchSubjectsState = useSelector((state: any) => state.batchSubjects);
  const dispatch = useDispatch();
  const state = store.useState();

  return (
    <div>
      {batchSubjectsState.subjects && (
        <StyledList>
          <div className={"header"} style={{ padding: "16px 16px 8px 16px" }}>
            <Row justify={"space-between"}>
              <Col>
                <h3>Courses({batchSubjectsState?.subjects?.length || 0})</h3>
              </Col>
            </Row>
            <div>
              <Search
                placeholder="Search with subject name"
                onSearch={(value) =>
                  dispatch(
                    onSearch({
                      value: value,
                    })
                  )
                }
                onChange={(e) =>
                  dispatch(
                    onSearch({
                      value: e.target.value,
                    })
                  )
                }
              />
            </div>
          </div>
          <Divider style={{ width: "100%", margin: 0 }} />
          <div className={"list"}>
            <List
              size="large"
              itemLayout="horizontal"
              dataSource={batchSubjectsState?.subjects || []}
              renderItem={(item: any, index) => (
                <StyledListItem
                  selected={batchSubjectsState.selectedSubjectIndex == index}
                >
                  <List.Item
                    onClick={(e) =>
                      dispatch(
                        selectSubject({
                          subjectIndex: index,
                          subjectId: item.subjectId,
                          batch: state.filter.batch,
                        })
                      )
                    }
                  >
                    <List.Item.Meta
                      title={`${item.name} [${item.subjectCode}]`}
                      description={`[${item.refCode}] - ${item.type}`}
                    />
                    <div style={{ color: "#C02632" }}>
                      Credits : {item.credits}
                    </div>
                  </List.Item>
                </StyledListItem>
              )}
            />
          </div>
        </StyledList>
      )}
    </div>
  );
}

export default CurriculumSubjects;
