import {
  Button,
  Checkbox,
  Col,
  Input,
  InputNumber,
  Modal,
  Row,
  Space,
  Typography,
} from "antd";
import serverErrorDialog from "components/serverErrorDialog";
import { Table } from "components/Table";
import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearState,
  fetchInternalMarks,
  showImportMarksFromExcelSheetDialog,
  importingInternalMarks,
  updateAbsent,
  updateMarks,
} from "redux/slices/internalMarksSlice";
import { PermissionsStore } from "shared-state/PermissionsStore";
import axios from "utils/axios";
import { examsStore } from "../../../layouts/ExamsLayout/ExamsLayout";
import { setInternalMarksLoading } from "../../../redux/slices/batchSubjectsSlice";
import { store as batchesStore } from "../Batches";

export default function InternalMarks() {
  const state = useSelector((state: any) => state.internalMarks);
  const batchSubjectsState = useSelector((state: any) => state.batchSubjects);
  const permissions = PermissionsStore.useState((s) => s.permissions);
  const [internalMarksExcelFile, setInternalMarksExcelFile] = useState(null);

  const dispatch = useDispatch();
  const batchesState = batchesStore.useState();
  const [reasonsOpen, setReasonsOpen] = useState(null);
  const inputRef: any = useRef()
  React.useEffect(() => {
    let subIndex = batchSubjectsState.selectedSubjectIndex;
    let sub = batchSubjectsState.subjects[subIndex];

    dispatch(
      fetchInternalMarks({
        subjectId: sub.subjectId,
        branchCode: batchesState.filter.branchCode,
        courseId: sub.courseId,
        batch: batchesState.filter.batch,
        semNo: sub.semNo,
      })
    );
  }, [batchSubjectsState.selectedSubjectIndex]);

  function updateInternalMarks() {
    let subIndex = batchSubjectsState.selectedSubjectIndex;
    let sub = batchSubjectsState.subjects[subIndex];
    dispatch(setInternalMarksLoading(true));
    axios
      .get("/stud-internal/update-marks-from-lms", {
        params: {
          subjectId:
            batchSubjectsState.subjects[batchSubjectsState.selectedSubjectIndex]
              .subjectId,
          batch: batchesState.filter.batch,
        },
      })
      .then((res) => {
        dispatch(
          fetchInternalMarks({
            subjectId: sub.subjectId,
            branchCode: batchesState.filter.branchCode,
            courseId: sub.courseId,
            batch: batchesState.filter.batch,
            semNo: sub.semNo,
          })
        );
        dispatch(setInternalMarksLoading(false));
      })
      .catch((e) => serverErrorDialog({ error: e }));
  }

  function importMarksFromExcelSheet() {
    let subIndex = batchSubjectsState.selectedSubjectIndex;
    let sub = batchSubjectsState.subjects[subIndex];

    if (!internalMarksExcelFile) {
      alert('Please select excel file');
      return;
    }

    let formData = new FormData();
    formData.append('file', internalMarksExcelFile);
    formData.append('subjectId', sub.subjectId)
    formData.append('branchCode', batchesState.filter.branchCode)
    formData.append('courseId', batchesState.filter.courseId)
    formData.append('batch', batchesState.filter.batch)
    formData.append('semNo', batchesState.filter.semNo);

    dispatch(importingInternalMarks(true))

    axios.post('/stud-internal/import-marks-from-excel-sheet', formData)
      .then(res => {
        console.log(res.data);
        Modal.success({
          content: 'Internal marks imported successfully'
        });
        dispatch(
          fetchInternalMarks({
            subjectId: sub.subjectId,
            branchCode: batchesState.filter.branchCode,
            courseId: sub.courseId,
            batch: batchesState.filter.batch,
            semNo: sub.semNo,
          })
        );
        dispatch(showImportMarksFromExcelSheetDialog(false))
        setInternalMarksExcelFile(null)
      })
      .catch(err => serverErrorDialog({ error: err }))
      .finally(() => dispatch(importingInternalMarks(false)))
  }

  return (
    <React.Fragment>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              loading={batchSubjectsState.internalMarksLoading}
              onClick={() => {
                if (!permissions.can_update_internal_marks_from_lms) {
                  Modal.warning({
                    content:
                      "You do not have enough permissions to update internal marks",
                  });
                  return;
                }
                Modal.confirm({
                  content: (
                    <div>
                      Are you sure that you want to update internal marks from
                      LMS?
                    </div>
                  ),
                  onOk: () => updateInternalMarks(),
                });
              }}
            >
              Update Internal marks from LMS
            </Button>

            <Button onClick={() => dispatch(showImportMarksFromExcelSheetDialog(true))}>
              Import marks from excel sheet
            </Button>

            <Button
              onClick={() => {
                setReasonsOpen(true);
              }}
            >
              Update Reasons
            </Button>
          </div>
        </Col>
        <Col xs={24}>{state.data.length > 0 && <InternalMarksTable />}</Col>
        <Col>
          <Modal
            onCancel={() => {
              setReasonsOpen(false)
              setInternalMarksExcelFile(null)
            }}
            title={`Update Reasons`}
            visible={reasonsOpen}
            footer={null}
          >
            <div>
              <div>
                <Table>
                  <table width="100%" style={{ marginBottom: 24 }}>
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Updated on</th>
                        <th>Updated by</th>
                        <th>Reason</th>
                      </tr>
                    </thead>
                    <tbody>
                      {state?.reasons?.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{moment(item.createdAt).format("DD-MM-YYYY")}</td>
                          <td>{item?.user?.fullName}</td>
                          <td>{item.reason}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Table>
              </div>
            </div>
          </Modal>
        </Col>
      </Row>

      <Modal
        visible={state.showImportMarksFromExcelSheetDialog}
        title="Import Marks from excel sheet"
        onCancel={() => {
          dispatch(showImportMarksFromExcelSheetDialog(false))
          setInternalMarksExcelFile(null)
        }}
        footer={[
          <Button key="back" onClick={() => {
            dispatch(showImportMarksFromExcelSheetDialog(false))
            setInternalMarksExcelFile(null)
          }}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={state.importingInternalMarks}
            onClick={importMarksFromExcelSheet}
          >
            Import
          </Button>,
        ]}
      >
        <div>
          <Space size={16} direction="vertical">
            <div>
              <Space>
                <Button onClick={() => inputRef.current.click()}>Choose file</Button>
                <Typography>{internalMarksExcelFile?.name}
                </Typography>
              </Space>
              <input ref={inputRef} style={{ display: "none" }} type="file" onChange={e => {
                setInternalMarksExcelFile(e.target.files[0]);
                e.target.value = ''
              }} />
            </div>
            <div>
              <a
                type={"link"}
                href={"/static_files/sample_internal_marks_template.xlsx"}
                download
              >
                Download sample file
              </a>
            </div>
          </Space>
        </div>
      </Modal>
    </React.Fragment>
  );
}

function InternalMarksTable() {
  const state = useSelector((state: any) => state.internalMarks);
  const permissions = PermissionsStore.useState((s) => s.permissions);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [reason, setReason] = useState(null);

  const batchesState = batchesStore.useState();

  useEffect(() => {
    return () => {
      dispatch(clearState({}));
    };
  }, []);

  function onSubmit() {
    // const emptyStudentMarks = state.data.filter((item) => item.marks == null);
    // if (emptyStudentMarks.length > 0) {
    //   Modal.warning({ content: "Please Enter all students marks" });
    //   return;
    // }
    let data = {
      batch: batchesState.filter.batch,
      reason: reason,
      courseId: state.courseId,
      subjectId: state.subjectId,
      branchCode: state.branchCode,
      semNo: state.semNo,
      marks: state.data
        .filter((item) => item.marks != null)
        .map((item) => ({
          studentId: item.id,
          marks: item.marks,
          absent: item.absent == 0 ? false : true,
        })),
    };

    setLoading(true);
    axios
      .post("/stud-internal", data)
      .then((res) => {
        Modal.success({ content: "Submitted successfully" });
        setOpen(false);
      })
      .catch((e) => serverErrorDialog({ error: e }))
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div>
      <Table>
        <table width="100%" style={{ marginBottom: 24 }}>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Subject Name</th>
              <th>Roll No</th>
              <th>Name</th>
              <th>Marks</th>
              <th>Absent</th>
            </tr>
          </thead>
          <tbody>
            {state.data.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.name}</td>
                <td>{item.roll_no}</td>
                <td>{item.student_name}</td>
                <td>
                  <InputNumber
                    value={item.marks}
                    min={0}
                    disabled={item.absent == 1}
                    onChange={(e) => {
                      dispatch(
                        updateMarks({
                          key: "marks",
                          index: index,
                          value: e,
                        })
                      );
                    }}
                  />
                  {` / `}
                  {item.int_max}
                </td>
                <td>
                  <Checkbox
                    checked={item.absent == 1}
                    onChange={(e) => {
                      dispatch(
                        updateAbsent({
                          key: "absent",
                          index: index,
                          value: e.target.checked == true ? 1 : 0,
                        })
                      );
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Table>
      <div
        style={{
          textAlign: "left",
          marginBottom: 16,
          paddingTop: 16,
        }}
      >
        <Button
          type="primary"
          onClick={() => {
            if (!permissions.can_update_internal_marks_from_ums) {
              Modal.warning({
                content:
                  "You do not have enough permissions to update internal marks",
              });
              return;
            }
            setOpen(true);
          }}
        >
          Submit Marks
        </Button>
      </div>

      <Modal
        onCancel={() => { setOpen(false) }}
        title={`Update marks`}
        visible={open}
        footer={null}
      >
        <div>
          <form>
            <Input.TextArea
              rows={4}
              onChange={(e) => setReason(e.target.value)}
              required
              value={reason}
              placeholder="Reason for updating..."
            />
            <Space
              style={{
                marginTop: 20,
                display: "flex",
                justifyContent: "flex-end",
              }}
              size="middle"
            >
              <Button onClick={() => setOpen(false)}>Cancel</Button>
              <Button
                loading={loading}
                onClick={() => onSubmit()}
                type="primary"
              >
                Submit
              </Button>
            </Space>
          </form>
        </div>
      </Modal>
    </div>
  );
}
