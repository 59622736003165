import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function Spinner() {
  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        height: "200px",
        display: "flex",
      }}
    >
      <Spin />
    </div>
  );
}

export default Spinner;
