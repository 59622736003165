import { createSlice } from "@reduxjs/toolkit";
import { ofType } from "redux-observable";
import { switchMap, map, filter } from "rxjs/operators";
import axios from "utils/axios";

interface StudentsState {
  data: Array<any>;
  error: any;
  loading: boolean;
  students: Array<any>;
  filteredData: Array<any>;
  selectedRowKeys: Array<any>;
  visible: boolean;
  batch: string;
  courseId: number;
  branchCode: string;
  studentsTableKey: number;
}

const initialState: StudentsState = {
  data: [],
  error: null,
  loading: false,
  students: [],
  filteredData: [],
  selectedRowKeys: [],
  visible: false,
  batch: null,
  courseId: null,
  branchCode: null,
  studentsTableKey: 0,
};

function isNumber(n) {
  return !isNaN(parseFloat(n)) && !isNaN(n - 0);
}

const studentsSlice = createSlice({
  name: "students",
  initialState,
  reducers: {
    fetchStudents(state, action) {
      state.loading = true;
      state.batch = action.payload.params.batch;
      state.courseId = action.payload.params.courseId;
      state.branchCode = action.payload.params.branchCode;
    },
    refreshStudents(state) {},
    fetchingStudents(state) {
      state.loading = true;
    },
    fetchedStudents(state, action) {
      state.data = action.payload.data;
      state.students = action.payload.students;
      state.loading = false;
      state.error = null;
      state.studentsTableKey = state.studentsTableKey + 1;
    },
    onSelectChange(state, action) {
      state.selectedRowKeys = action.payload.selectedRowKeys;
    },
    unSelectAll(state) {
      state.selectedRowKeys = [];
    },
    onSearch(state, action) {
      let value = action.payload.value;
      if (!value) {
        state.students = state.data;
        return;
      }

      state.students = state.data.filter((student) => {
        if (student.rollNo && student.rollNo.includes(value.toUpperCase())) {
          return true;
        }
      });
    },
    showDrawer(state) {
      state.visible = true;
    },
    hideDrawer(state) {
      state.visible = false;
    },
  },
});

export const {
  fetchStudents,
  refreshStudents,
  onSelectChange,
  fetchedStudents,
  onSearch,
  unSelectAll,
  showDrawer,
  hideDrawer,
} = studentsSlice.actions;

export function fetchStudentsEpic(action$, state$) {
  return action$.pipe(
    ofType(fetchStudents.type, refreshStudents.type),
    switchMap(async (action: any) => {
      console.log(state$);
      try {
        let res = await axios.get(`/students-list`, {
          params: {
            batch: state$.value.students.batch,
            courseId: state$.value.students.courseId,
            branchCode: state$.value.students.branchCode,
          },
        });

        return {
          data: res.data,
          students: res.data,
        };
      } catch (err) {
        console.log(err);
        alert(`Error occurred: [${err.message}]`);
      }
    }),
    map((data) => fetchedStudents(data))
  );
}

export default studentsSlice.reducer;
