import "antd/dist/antd.less";
import ErrorBoundary from "components/ErrorBoundary";
import _ from "lodash";
import LoginPage from "pages/LoginPage/LoginPage";
import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import store from "redux/store";
import axios from "utils/axios";
import "./main.less";
import "./App.less";
import routes from "./routes";
import { QueryClient, QueryClientProvider } from "react-query";
import ResetPassword from "pages/ResetPassword";
import { UserStore } from "shared-state/UserStore";
import { PermissionsStore } from "shared-state/PermissionsStore";
import { ErrorStore } from "shared-state/ErrorStore";
import { AssetsStore } from "shared-state/AssetsStore";
import styled from "styled-components";

const StyledError = styled.div`
  position: absolute;
  width: 600px;
  border: 1px solid #dedede;
  left: 50%;
  top: 50%;
  background: white;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  .title {
    background: #233659;
    color: white;
    padding: 0 12px;
    margin-bottom: 0;
  }

  .content {
    padding: 8px;
    color: #ef5350;
    min-height: 140px;
  }
`;

const queryClient = new QueryClient();

axios.interceptors.response.use(
  function (response) {
    ErrorStore.update((s) => {
      s.error = null;
      s.statusCode = null;
      s.data = null;
    });
    return response;
  },
  function (err) {
    console.log(err);
    console.log(err.message);

    const message = {
      heading: "",
      description: "",
    };

    if (err.message == "Network Error") {
      ErrorStore.update((s) => {
        s.message.heading = err.message;
        s.message.description = "Please check your internet connection";
      });
    } else if (err.response?.status == 401) {
      message.heading = "Session Expired!";
      message.description =
        "Your session has been expired, Please login to continue";
      if (localStorage.getItem("token")) {
        localStorage.removeItem("token");
        window.location.reload();
      }
    } else if (err.response?.status == 403) {
      message.heading = "Forbidden!";
      message.description =
        "The resource you are trying to access is forbidden.";
    } else {
      message.heading = "Unknown Error!";
      message.description = "Unknown error occurred!";
    }

    if (err.response?.status == 400 || err.response?.status == 422) {
      ErrorStore.update((s) => {
        s.error = null;
        s.statusCode = null;
        s.data = null;
      });
      return Promise.reject(err);
    }    

    return Promise.reject(err);
  }
);

export const UserContext = React.createContext<any>(null);

function App() {
  if (window.location.pathname == "/reset-password") {
    return <ResetPassword />;
  }

  if (!localStorage.getItem("token")) {
    return <LoginPage />;
  }

  return <AppContent />;
}

function AppContent() {
  const userState = UserStore.useState((s) => s);
  const errorState = ErrorStore.useState((s) => s);
  const permssionsState = PermissionsStore.useState();

  useEffect(() => {
    axios
      .get("/my-permissions")
      .then((res) => {
        UserStore.update((s) => {
          s.username = res.data.username;
          s.user = res.data.user;
          s.roles = res.data.roles;
        });

        PermissionsStore.update((s) => {
          s.permissions = res.data?.permissions as any;
        });

        AssetsStore.update((s) => {
          s.logo = res.data.assets.logo;
          s.logo_square = res.data.assets.logo_square;
        });
      })
      .catch((err) => {
        ErrorStore.update((s) => {
          s.error = err.message;
          s.statusCode = err.response?.statusCode;
          s.data = err.response?.data;
        });
      });
  }, []);

  if (errorState.error) {
    return (
      <div style={{ position: "relative", width: "100%", height: "100vh" }}>
        <StyledError>
          <h2 className="title">{errorState.message.heading}</h2>
          <div className="content">
            <div>{errorState.message.description}</div>
          </div>
        </StyledError>
      </div>
    );
  }

  if (!permssionsState.permissions) {
    return null;
  }
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ErrorBoundary>
          <NetworkErrorView />
          <UserContext.Provider value={userState}>
            <div className="App">
              <BrowserRouter>
                <Switch>
                  {routes.map((route, index) => {
                    if (route.routes) {
                      return (
                        <Route
                          key={index}
                          exact={route.exact}
                          path={route.path}
                        >
                          <route.component
                            routes={route.routes}
                            pageTitle={route.name}
                          />
                        </Route>
                      );
                    } else {
                      return (
                        <Route
                          key={index}
                          exact={route.exact}
                          path={route.path}
                          component={route.component}
                        />
                      );
                    }
                  })}
                  <Route path="*">
                    <div>Route not found</div>
                  </Route>
                </Switch>
              </BrowserRouter>
            </div>
          </UserContext.Provider>
        </ErrorBoundary>
      </Provider>
    </QueryClientProvider>
  );
}

function NetworkErrorView() {
  const errorState = ErrorStore.useState((s) => s);

  if (!errorState.error) {
    return null;
  }

  return (
    <div
      style={{
        position: "absolute",
        marginLeft: "auto",
        marginRight: "auto",
        zIndex: 100,
        textAlign: "center",
        width: "100%",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <span
          style={{
            background: "#f9edbe",
            border: "1px solid #f0c36d",
            padding: "4px 8px",
          }}
        >
          <b>{"Network Error"}</b>
        </span>
      </div>
    </div>
  );
}

export default App;
