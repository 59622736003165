import { Col, Row, Tabs } from "antd";
import InternalMarks from "pages/Batches/InternalMarks/InternalMarks";
import React from "react";
import { useSelector } from "react-redux";

function MarksTabs(props) {
  const state = useSelector((state: any) => state.batchSubjects);
  return (
    <div>
      {state.selectedSubjectIndex != null && (
        <div>
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <div
                style={{
                  background: "white",
                  borderRadius: 16,
                  cursor: "pointer",
                }}
              >
                <div style={{ padding: "16px 8px 0px 8px" }}>
                  <h3>
                    {state.subjects[state.selectedSubjectIndex]?.name} -
                    Internal Marks
                  </h3>
                </div>
                <div style={{ padding: 8 }}>
                  <InternalMarks />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}

export default MarksTabs;
