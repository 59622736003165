import {createSlice} from "@reduxjs/toolkit";
import axios from "utils/axios";
import {ofType} from 'redux-observable';
import {switchMap, map} from 'rxjs/operators';

const initialState = {
  loading: true,
  data: null,
  error: null,
}

const clubsSlice = createSlice({
  name: 'clubs',
  initialState: initialState,
  reducers: {
    fetchClubs(state, action: any) {
    },
    fetchedClubs(state, action: any) {
      state.loading = false;
      state.data = action.payload.data;
    },
  }
});

export function fetchClubsEpic(action$, state$) {
  return action$.pipe(
    ofType(fetchClubs.type),
    switchMap(async (action: any) => {
      const res = await axios.get('/clubs');
      return {
        data: res.data,
      }
    }),
    map(fetchedClubs),
  );
}


export const {fetchClubs, fetchedClubs} = clubsSlice.actions

export default clubsSlice.reducer;