import React from "react";
import Spinner from "components/Spinner";
import { StyledTableContainer } from "components/StyledTableContainer";
import useFetch from "hooks/useFetch";
import { Header } from "pages/TSheets/Header";
import styled from "styled-components";

export default function TRWithMarks() {
  const urlParams: any = new URLSearchParams(window.location.search);
  const monthYear = urlParams.get("monthYear");
  const examType = urlParams.get("examType");
  const courseId = urlParams.get("courseId");
  const semNo = urlParams.get("semNo");
  const regulation = urlParams.get("regulation");
  const branchCode = urlParams.get("branchCode");
  const batch = urlParams.get("batch");

  const { data, loading } = useFetch(
    `/marks/students?semNo=${semNo}&regulation=${regulation}&courseId=${courseId}&branchCode=${branchCode}&batch=${batch}&examType=${examType}&monthYear=${monthYear}`
  );

  function marksStyle(item) {
    let obj: any = {};
    if (item?.adjustedMarks > 0) {
      obj = {
        fontWeight: "bold",
        color: "green",
      };
    } else if (item?.adjustedMarks < 0) {
      obj = {
        fontWeight: "bold",
        color: "red",
      };
    }

    if (item?.mModrks) {
      obj.textDecoration = "underline";
    }

    return obj;
  }

  if (loading) {
    return <Spinner />;
  }

  const removedStyle: any = {
    fontWeight: "bold",
    color: "red",
  };

  const StyledDiv = styled.div`
    table td,
    th {
      text-align: center;
      color: black;
    }

    .font16px {
      font-size: 16px;
    }
  `;

  return (
    <div style={{ background: "white" }}>
      {data.studentsMarks.map((branchItem, idx) => (
        <div style={{ padding: 12, pageBreakAfter: "always" }}>
          <Header
            schoolName={data.school.name}
            logo={data.school.logoSquare}
            address={data.school.address}
            branchCode={branchItem.branchCode}
            semester={data.semester}
            course={data.course.courseName}
            monthYear={data.monthYear}
            regulation={data.regulation}
            title={data.title}
          />
          <div
            style={{
              padding: 10,
              textAlign: "center",
              fontWeight: "bold",
              fontSize: 16,
            }}
          >
            TR With Marks After Moderation And Grafting
          </div>
          <StyledTableContainer>
            <StyledDiv>
              <table>
                <thead>
                  <tr>
                    <th rowSpan={2}>HT.No.</th>
                    {branchItem.subjects.map((item, index) => (
                      <th colSpan={4}>
                        <div>{item.refCode}</div>
                        <div>({item.subjectCode})</div>
                      </th>
                    ))}
                  </tr>
                  <tr>
                    {branchItem.subjects.map((item, index) => (
                      <>
                        <th>Int</th>
                        <th>Ext</th>
                        <th>Tot</th>
                        <th>Gr.</th>
                      </>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {branchItem.studentsMarks.map((item, sIndex) => (
                    <tr>
                      <td className="font16px">{item.rollNo}</td>
                      {branchItem.subjects.map((subject, index) => {
                        const nData = item.subjects.filter(
                          (item) => item.subject_code == subject.subjectCode
                        )[0];
                        return (
                          <>
                            <td className="font16px">
                              {nData?.int_marks ?? "--"}
                            </td>
                            <td className="font16px">
                              {nData?.ext_marks ?? "--"}
                            </td>
                            <td
                              className="font16px"
                              style={{
                                ...marksStyle(nData),
                                fontSize: "16px",
                              }}
                            >
                              {nData?.total_marks ?? "--"}
                            </td>
                            <td className="font16px">{nData?.grade ?? "--"}</td>
                          </>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </StyledDiv>
          </StyledTableContainer>
        </div>
      ))}
    </div>
  );
}
