import {createSlice} from "@reduxjs/toolkit";
import {ofType} from 'redux-observable';
import {switchMap, map, filter} from 'rxjs/operators';
import axios from "utils/axios";
import * as XLSX from 'xlsx';
import serverErrorDialog from "../../components/serverErrorDialog";

interface AdmissionsState {
  data: Array<any>
  error: any
  loading: boolean
  admissions: Array<any>
  filteredData: Array<any>
  feeOptions: Array<any>
  selectedRowKeys: Array<any>
  showFeeAllotModal: boolean
  filters: any
}

const initialState: AdmissionsState = {
  data: [],
  error: null,
  loading: false,
  admissions: [],
  filteredData: [],
  feeOptions: [],
  selectedRowKeys: [],
  showFeeAllotModal: false,
  filters: {
    programId: null,
    courseId: null,
    batch: null,
    quotaId: null,
    status: null,
    isStudent: null,
  }
};

const admissionsSlice = createSlice({
  name: 'admissions',
  initialState,
  reducers: {
    fetchAdmissions(state) {
      state.loading = true
    },
    // fetchingAdmissions(state) {
    //   state.loading = true;
    // },
    fetchedAdmissions(state, action) {
      state.data = action.payload.data;
      state.admissions = action.payload.admissions;
      state.feeOptions = action.payload.feeOptions;
      state.loading = false;
      state.error = null;
    },
    showFeeAllotModal(state) {
      state.showFeeAllotModal = true;
    },
    hideFeeAllotModal(state) {
      state.showFeeAllotModal = false;
    },
    onSelectChange(state, action) {
      state.selectedRowKeys = action.payload.selectedRowKeys
    },
    unSelectAll(state) {
      state.selectedRowKeys = [];
    },
    onSearch(state, action) {
      let value = action.payload.value;
      if (!value) {
        state.admissions = state.data;
        return;
      }
      
      state.admissions = state.data.filter(admission => {
        if (admission.interHallTicketNumber && `${admission.interHallTicketNumber || ''}`.includes(value.toUpperCase())) {
          return true
        } else if (admission.fullName && admission.fullName.includes(value.toUpperCase())) {
          return true
        } else if (admission.admissionNo && admission.admissionNo.includes(value.toUpperCase())) {
          return true
        }
      });
    },
    onFilter(state, action) {
      let filters = action.payload.filters;
      
      state.filteredData = state.data.filter(admission => {
        if (filters.quota) {
          return filters.quota.includes(admission.quota)
        }
        
        return true;
      }).filter(admission => {
        if (filters.branchCode) {
          return filters.branchCode.includes(admission.branchCode)
        }
        
        return true;
      }).filter(admission => {
        if (filters.status) {
          return filters.status.includes(admission.status)
        }
        
        return true;
      })
    },
    applyFilters(state, action) {
      state.filters = {
        ...state.filters,
        ...action.payload
      }
    },
    exportToExcel(state) {
      if (!state.data) {
        alert('Please fetch the report first');
        return;
      }
      
      const excelData = state.admissions.map((item, index) => ({
        'S.No': index + 1,
        'Admission Id': item.id,
        'Section': '',
        'AU Hall Ticket No': item.rollNo,
        'Student Name': item.fullName,
        'Gender': item.gender,
        'Email': item.email,
        'Alternate Email': item.alternateEmail,
        'Mobile': item.mobile,
        'Alternate Mobile': item.alternateMobile,
        'Mother Tongue': item.motherTongue,
        'Nationality': item.nationality,
        'Religion': item.religion,
        'Caste': item.caste,
        'Aadhaar No': item.aadhaarNo,
        'Batch': item.batch,
        'Course': item.course.courseName,
        'Program': item.program.branchCode,
        'Quota': item.quota,
        'Rank': item.rank,
        'Guardian Name': item.guardianName,
        'Father Name': item.parentName,
        'Father Mobile': item.parentMobile,
        'Father Occupation': item.parentOccupation,
        'Mother Name': item.motherName,
        'Mother Occupation': item.motherOccupation,
        'Admission Status': item.status,
      }))
      
      const fileName = `Admissions Report.xlsx`;
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Admissions");
      XLSX.writeFile(wb, fileName);
    }
  }
});

export const {
  fetchAdmissions,
  onSelectChange,
  fetchedAdmissions,
  onSearch,
  showFeeAllotModal,
  hideFeeAllotModal,
  unSelectAll,
  onFilter,
  exportToExcel,
  applyFilters,
} = admissionsSlice.actions

export function fetchAdmissionsEpic(action$, state$) {
  return action$.pipe(
    ofType(fetchAdmissions.type),
    switchMap(async (action: any) => {
      try {
        const state = state$.value.admissions
        let res = await axios.get(`/admissions`, {
          params: {
            programId: state.filters.programId || undefined,
            courseId: state.filters.courseId || undefined,
            batch: state.filters.batch || undefined,
            quotaId: state.filters.quotaId || undefined,
            status: state.filters.status || undefined,
            isStudent: state.filters.isStudent || undefined,
          }
        });
        let allFeeOptionsRes = await axios.get(`/all-fee-options`);
        
        let data = res.data.map((item, index) => ({
          sNo: index + 1,
          ...item
        }))
        
        return {
          data: data,
          feeOptions: allFeeOptionsRes.data,
          admissions: data,
        };
      } catch (err) {
        serverErrorDialog({error: err})
        return {
          data: [],
          feeOptions: {
            fees: []
          },
          admissions: [],
        }
      }
    }),
    map(data => fetchedAdmissions(data))
  );
}


export function hideFeeAllotModalEpic(action$) {
  return action$.pipe(
    ofType(hideFeeAllotModal.type),
    map(data => unSelectAll())
  );
}

export default admissionsSlice.reducer;
