import { Store } from 'pullstate'

export interface IAssetsStore {
  logo: string;
  logo_square: string;
  school_name: string;
}

export const AssetsStore = new Store({
  logo: null,
  logo_square: null,
  school_name: null
});