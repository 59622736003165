import { createSlice } from '@reduxjs/toolkit'
import { ofType } from 'redux-observable'
import { switchMap, map } from 'rxjs/operators'
import axios from "../../utils/axios";
import { callAsyncCircusFn } from "jest-circus/build/utils";
import serverErrorDialog from "../../components/serverErrorDialog";
import _ from 'lodash'

const initialState = {
  data: {},
  bundles: [],
  error: null,
  loading: true,
  selectedBundle: null,
  selectedBundleIndex: null,
  showAddBookletsButton: false,
  editBundleIndex: null,
  exam: null,
  selectedBundleSheets: [],
  subject: null,
  evaluators: [],
  params: null
}


const bundlesSlice: any = createSlice({
  name: 'bundles',
  initialState: initialState,
  reducers: {
    fetchBundles(state, action) {
      state.loading = true
      state.params = action.payload.params
    },
    fetchedBundles(state, action) {
      state.loading = false
      state.data = action.payload.data
      state.exam = action.payload.data['exam']
      state.subject = action.payload.data['subject']
      state.bundles = action.payload.data['Evaluation']
    },
    fetchedEvaluators(state, action) {
      state.evaluators = action.payload.data
    },
    addBundle(state, action) {

    },
    addedBundle(state, action) {
      state.bundles = [...state.bundles, { ...action.payload.data, sheets: [] }]
    },
    editBundle(state, action) {
      state.editBundleIndex = action.payload.index
    },
    editedBundle(state, action) {
      if (action.payload.data) {
        state.bundles[state.editBundleIndex] = {
          ...state.bundles[state.editBundleIndex], ...action.payload.data
        }
      }
    },
    cancelEvaluation(state, action) {
    },
    canceledEvaluation(state, action) {
      if (action.payload.data) {
        state.bundles[state.selectedBundleIndex]['status'] = 'saved'
      }
    },
    onSearch(state, action) {
      let value = action.payload.value;
      if (!value) {
        state.bundles = state.data['Evaluation'];
        return;
      }
      state.bundles = state.data['Evaluation'].filter(bundle => {
        if (bundle.bundleSerialNo && bundle.bundleSerialNo.toUpperCase().includes(value.toUpperCase())) {
          return true
        }
      });
    },
    addSheet(state, action) {
    },
    addedSheet(state, action) {
      if (action.payload.data) {
        state.bundles[state.selectedBundleIndex].sheets = [...state.bundles[state.selectedBundleIndex].sheets,
        action.payload.data]
      }
    },
    deleteSheet(state, action) {
      delete state.bundles[state.selectedBundleIndex].sheets[action.payload.index];
      state.bundles[state.selectedBundleIndex].sheets = state.bundles[state.selectedBundleIndex].sheets.filter(f => f);
    },
    selectBundle(state, action) {
      state.selectedBundle = action.payload.selectedBundle
      state.selectedBundleIndex = action.payload.selectedBundleIndex
      state.selectedBundleSheets = state.bundles[action.payload.selectedBundleIndex].sheets
    },
    updateStatus(state, action) {
      console.log(state.bundles[state.selectedBundleIndex])
      state.bundles[state.selectedBundleIndex]['status'] = action.payload.status
      state.selectedBundle.status = action.payload.status
    },
    saveBundle(state, action) {
    },
    savedBundle(state, action) {
      console.log(state.bundles[state.selectedBundleIndex])
      state.bundles[state.selectedBundleIndex]['status'] = action.payload.status
      state.selectedBundle.status = action.payload.status
    },
    updateSheetMarks(state, action) {
      state.bundles[state.selectedBundleIndex].sheets[action.payload.index][action.payload.key]
        = action.payload.value
    },
  }
})


export function fetchBundlesEpic(action$) {
  return action$.pipe(
    ofType(fetchBundles.type),
    switchMap(async (action: any) => {
      const res = await axios.get(`/bundles`, {
        params: action.payload.params
      });
      return {
        data: res.data,
      }
    }),
    map(fetchedBundles),
  );
}

export function addBundleEpic(action$) {
  return action$.pipe(
    ofType(addBundle.type),
    switchMap(async (action: any) => {
      const res = await axios.post(`/bundles?examId=${action.payload.examId}&subjectId=${action.payload.subjectId}`, {
        bundleId: action.payload.bundleId,
        bundleType: 'Evaluation'
      });
      return {
        data: res.data,
      }
    }),
    map(addedBundle),
  );
}

export function addSheetEpic(action$, state$) {
  return action$.pipe(
    ofType(addSheet.type),
    switchMap(async (action: any) => {
      try {
        let bundleId = state$.value.bundles.selectedBundle.id
        const res = await axios.post(`/bundles/${bundleId}/add-sheet`, {
          barcode: action.payload.barcode
        });
        action.payload.form.resetFields()

        return {
          data: res.data,
        }
      } catch (e) {
        serverErrorDialog({ error: e })
        return {
          data: null
        }
      }
    }),
    map(addedSheet),
  );
}

export function saveBundleEpic(action$, state$) {
  return action$.pipe(
    ofType(saveBundle.type),
    switchMap(async (action: any) => {
      try {
        let bundleId = state$.value.bundles.selectedBundle.id
        const res = await axios.get(`/bundles/${bundleId}/save`);
        return {
          status: 'saved',
        }
      } catch (e) {
        serverErrorDialog({ error: e })
        return {
          status: 'created'
        }
      }
    }),
    map(savedBundle),
  );
}

export function fetchEvaluatorsEpic(action$, state$) {
  return action$.pipe(
    ofType(selectBundle.type, updateStatus.type, canceledEvaluation.type),
    switchMap(async (action: any) => {
      let bundleId = state$.value.bundles.selectedBundle.id
      const res = await axios.get(`/bundles/${bundleId}/evaluators`);
      return {
        data: res.data,
      }
    }),
    map(fetchedEvaluators),
  );
}

export function editBundleEpic(action$, state$) {
  return action$.pipe(
    ofType(editBundle.type),
    switchMap(async (action: any) => {
      try {
        const res = await axios.put(`/bundles/${action.payload.bundleId}`, {
          bundleSerialNo: action.payload.bundleSerialNo
        });
        return {
          data: res.data
        }
      } catch (e) {
        serverErrorDialog({ error: e })
        return {
          data: null
        }
      }
    }),
    map(editedBundle),
  );
}

export function cancelEvaluationEpic(action$, state$) {
  return action$.pipe(
    ofType(cancelEvaluation.type),
    switchMap(async (action: any) => {
      try {
        let bundleId = state$.value.bundles.selectedBundle.id
        const res = await axios.get(`/bundles/${bundleId}/cancel`);
        return {
          data: res.data
        }
      } catch (e) {
        serverErrorDialog({ error: e })
        return {
          data: null
        }
      }
    }),
    map(canceledEvaluation),
  );
}

const {
  fetchedBundles,
  addedBundle,
  savedBundle,
  addedSheet,
  fetchedEvaluators,
  editedBundle,
  canceledEvaluation
} = bundlesSlice.actions
export const {
  fetchBundles,
  addBundle,
  selectBundle,
  addSheet,
  saveBundle,
  updateStatus,
  deleteSheet,
  editBundle,
  onSearch,
  cancelEvaluation,
  updateSheetMarks,
} = bundlesSlice.actions

export default bundlesSlice.reducer