import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ofType } from 'redux-observable';
import axios from 'utils/axios';
import { switchMap, map } from 'rxjs/operators';

export interface FeePaymentsState {
  data: any,
  error: any,
  loading: boolean,
  hallTicket: string,
  feeDetailsByYear: any,
  showPaymentModal: boolean,
  paymentModalData: any,
  showFeeAllotModal: boolean,
  feeOptions: any,
  receipts: any,
  selectAll: boolean,
  admission: any,
  showReceiptEditDialog: boolean,
  editingReceipt: any,
  student:any
}

const initialState: FeePaymentsState = {
  hallTicket: '',
  data: null,
  error: null,
  loading: true,
  feeDetailsByYear: {},
  showPaymentModal: false,
  paymentModalData: {
    fees: [],
    payingFees: [],
    paymentDetails: null
  },
  showFeeAllotModal: false,
  feeOptions: null,
  receipts: [],
  selectAll: false,
  admission: null,
  showReceiptEditDialog: false,
  editingReceipt: null,
  student:null
}

const feePaymentsSlice = createSlice({
  name: 'feePayments',
  initialState,
  reducers: {
    fetchStudentFeeDetails(state, action) {
      state.loading = true;
      state.hallTicket = action.payload.hallTicket;
    },
    fetchedStudentFeeDetails(state, action) {
      state.loading = false;
      state.data = action.payload.data;
      state.feeOptions = action.payload.feeOptions;
      state.feeDetailsByYear = action.payload.feeDetailsByYear;
      state.receipts = action.payload.receipts;
      state.admission = action.payload.admission;
      state.student = action.payload.student;
    },
    showFeePaymentModal(state, action) {
      let fees = action.payload.data.fees;
      let paymentDetails = {
        course: fees[0].print_name,
        totalFee: 0,
        feeItems: fees.map(item => {
          return {
            feeName: `${item.print_name}`,
            payingAmount: item.payingAmount,
            feeParticulars: item.feeParticulars
          }
        })
      }

      let payingFees = fees.filter(item => {
        return item.payingAmount && item.payingAmount > 0
      })
        .map(item => ({
          ...item,
          amount: `${item.payingAmount}`
        }));

      paymentDetails.totalFee = paymentDetails.feeItems.reduce((sum, next) => {
        return sum + parseFloat(next.payingAmount);
      }, 0)

      state.showPaymentModal = true;
      state.paymentModalData = {
        fees: fees,
        payingFees: payingFees,
        paymentDetails: paymentDetails
      }
    },
    hideFeePaymentModal(state) {
      state.showPaymentModal = false;
      state.paymentModalData = {
        fees: [],
        payingFees: [],
        paymentDetails: null
      };
    },
    showFeeAllotModal(state) {
      state.showFeeAllotModal = true;
    },
    hideFeeAllotModal(state) {
      state.showFeeAllotModal = false;
    },
    showReceiptEditDialog(state, action) {
      state.showReceiptEditDialog = true;
      state.editingReceipt = action.payload
    },
    hideReceiptEditDialog(state) {
      state.showReceiptEditDialog = false;
    }
  },
});

export const {
  fetchStudentFeeDetails, fetchedStudentFeeDetails, showFeePaymentModal, hideFeePaymentModal,
  showFeeAllotModal, hideFeeAllotModal, showReceiptEditDialog, hideReceiptEditDialog
} = feePaymentsSlice.actions

export function fetchStudentFeeDetailsEpic(action$) {
  return action$.pipe(
    ofType(fetchStudentFeeDetails.type),
    switchMap(async (action: any) => {
      try {
        let feeDetailsRes = await axios.get(`/student-fee-details`, {
          params: {
            interHallTicketNumber: action.payload.hallTicket
          }
        });

        let availableFeeOptionsRes = await axios.get(`/available-student-fee-options`, {
          params: {
            interHallTicketNumber: action.payload.hallTicket,
          }
        });

        const receiptsRes = await axios.get(`/fee-receipts`, {
          params: {
            hallTicket: action.payload.hallTicket
          }
        })

        let feeDetailsByYear = { ...feeDetailsRes.data.feeDetailsByYear };
        for (let year in feeDetailsByYear) {
          feeDetailsByYear[year] = feeDetailsByYear[year].map(item => {
            return {
              ...item,
              selected: false,
              payingAmount: item.dueAmount,
            };
          })
        }

        return {
          data: feeDetailsRes.data,
          student: availableFeeOptionsRes.data.student,
          feeOptions: availableFeeOptionsRes.data,
          receipts: receiptsRes.data,
          feeDetailsByYear: feeDetailsByYear,
          admission: feeDetailsRes.data.admission,
          loading: false,
          error: null
        };
      } catch (err) {
        console.log(err);
        alert(`Error occurred: [${err.message}]`)
      }
    }),
    map(data => fetchedStudentFeeDetails(data))
  );
}

export default feePaymentsSlice.reducer
