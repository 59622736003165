import {createSlice} from "@reduxjs/toolkit";
import moment from 'moment'
import {ofType} from "redux-observable";
import {map, switchMap} from "rxjs/operators";
import axios from "../../utils/axios";
import {fetchedEvents, fetchEvents} from "./eventsSlice";

const initialState = {
  loading: true,
  data: null
}

const slice = createSlice({
  name: 'phd',
  initialState: initialState,
  reducers: {
    fetchPhdApplications(state, action) {
      state.loading = true
    },
    fetchedPhdApplications(state, action) {
      state.loading = false
      state.data = action.payload.data
    }
  }
});
export function fetchPhdApplicationsEpic(action$, state$) {
  return action$.pipe(
    ofType(fetchPhdApplications.type),
    switchMap(async (action: any) => {
      const res = await axios.get('/phd',);
      return {
        data: res.data,
      }
    }),
    map(fetchedPhdApplications),
  );
}


export const {
  fetchPhdApplications,
  fetchedPhdApplications
} = slice.actions

export default slice.reducer;

