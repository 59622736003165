import loadable from "@loadable/component";
import PrintCourseRegistrations from "layouts/ExamsLayout/pages/CourseRegistrations/PrintCourseRegistrations";
import Batches from "pages/Batches";
import RevaluationReport from "./layouts/ExamsLayout/pages/Revaluation/RevaluationRequiredReport";
import PrintYellowSheetsReports from "./layouts/ExamsLayout/pages/Bundling/PrintYelloSheetsReports";
import TSheets from "pages/TSheets";
import TRWithMarks from "pages/TRWithMarks";
import TRWithMarksBeforeProcessing from "pages/TRWithMarksBeforeProcessing";
import SectionWiseAnalysisReport from "pages/Reports/SectionWiseAnalysis";

const AnuragCetAttendanctSheet = loadable(
  () => import("pages/AnuragCetAttendanctSheet")
);
const ExamsLayout = loadable(() => import("layouts/ExamsLayout"));
const ResetPassword = loadable(() => import("pages/ResetPassword"));
const SeatingPlanNoticeBoard = loadable(
  () => import("pages/SeatingPlanNoticeBoard")
);
const Phd = loadable(() => import("./pages/Phd"));
const ViewPHD = loadable(() => import("./pages/Phd/ViewPhd"));
const Halltickets = loadable(() => import("pages/Halltickets"));
const CourseRegistrations = loadable(
  () => import("./pages/CourseRegistrations")
);
const AgriCET = loadable(() => import("./pages/AgriCET/Applications"));
const MarksDivision = loadable(() => import("./pages/MarksDivision"));
const Clubs = loadable(() => import("./pages/Clubs"));
const GraftingAnalysis = loadable(
  () => import("pages/Reports/GraftingAnalysis")
);
const ModerationAnalysis = loadable(
  () => import("./pages/Reports/ModerationAnalysis")
);
const BeforeModeration = loadable(
  () => import("./pages/Reports/BeforeModeration")
);
const AdjustedMarksReport = loadable(
  () => import("./pages/Reports/AdjustedMarksReport")
);
const Reports = loadable(() => import("./pages/Reports"));
const Applications = loadable(() => import("./pages/AnuragCET/Applications"));
const PrintYellowSheets = loadable(
  () => import("./layouts/ExamsLayout/pages/Bundling/PrintYellowSheets")
);
const PrintExamFeeReport = loadable(
  () => import("./pages/ExamFeePayments/PrintExamFeeReport")
);
const ExamFeeReports = loadable(
  () => import("./pages/ExamFeePayments/ExamFeeReports")
);
const PrintDForm = loadable(() => import("./pages/ExamDetails/PrintDForm"));
const ExamFeeReceipt = loadable(
  () => import("./pages/ExamFeePayments/ExamFeeReceipt")
);
const ExamSeatingPlan = loadable(() => import("./pages/ExamSeatingPlan"));
const FeeReport = loadable(() => import("pages/FeeReport"));
const Departments = loadable(() => import("./pages/Departments"));
const AuditLogs = loadable(() => import("./pages/AuditLogs"));
const EventDetails = loadable(() => import("./pages/Events/EventDetails"));
const PrintApplication = loadable(
  () => import("pages/AdmissionsPage/PrintApplication")
);
const Dashboard = loadable(() => import("pages/Dashboard"));
const Students = loadable(() => import("pages/Students"));
const UpdateStudent = loadable(() => import("pages/Students/UpdateStudent"));
const ViewStudent = loadable(() => import("pages/Students/ViewStudent"));
const Streams = loadable(() => import("pages/Streams"));
const LevelOfStudy = loadable(() => import("pages/LevelOfStudy"));
const Semesters = loadable(() => import("pages/Semesters"));
const Curriculums = loadable(() => import("pages/Curriculums"));
const CurriculumDetailsPage = loadable(
  () => import("pages/Curriculums/CurriculumDetails")
);
const Sections = loadable(() => import("pages/Sections"));
const Subjects = loadable(() => import("pages/Subjects"));
const StudentSections = loadable(() => import("pages/StudentSections"));
const AdmissionsPage = loadable(() => import("pages/AdmissionsPage"));
const RolesAndPermissionsPage = loadable(
  () => import("pages/RolesAndPermissionsPage")
);
const UsersPage = loadable(() => import("pages/UsersPage"));
const LoginPage = loadable(() => import("pages/LoginPage/LoginPage"));
const TheLayout = loadable(() => import("components/TheLayout"));
const EditAdmission = loadable(
  () => import("pages/AdmissionsPage/AdmissionDetails")
);
const FeeStructure = loadable(() => import("pages/FeeStructure"));
const ReceiptPage = loadable(() => import("pages/ReceiptPage"));
const Scholarship = loadable(() => import("pages/Scholarship"));
const ExamTimetablePage = loadable(() => import("pages/ExamTimetablePage"));
const AwardMarks = loadable(() => import("pages/AwardMarks/AwardMarks"));
const ExamFeePayments = loadable(() => import("pages/ExamFeePayments"));
const ClassRooms = loadable(() => import("pages/ClassRooms"));
const Feed = loadable(() => import("pages/Feed"));
const CreateSubjectDetails = loadable(
  () => import("pages/SubjectDetails/CreateSubjectDetails")
);
const PrintReport = loadable(() => import("pages/FeeReport/PrintReport"));
const Events = loadable(() => import("pages/Events"));
const StudentFees = loadable(() => import("./pages/StudentFees"));
const ExamAttendanceSheet = loadable(
  () => import("./pages/ExamAttendanceSheet")
);
const ExamSessions = loadable(() => import("pages/ExamSessionsPage"));
const SeatingArrangement = loadable(() => import("pages/SeatingArrangement"));
const AddSeatingArrangement = loadable(
  () => import("pages/SeatingArrangement/SeatingArrangement")
);
const Bundles = loadable(() => import("pages/Bundles"));
const ManualBundles = loadable(() => import("pages/ManualBundles"));
const BundleCommonSubjects = loadable(
  () => import("pages/ManualBundles/BundleCommonSubjects")
);
const SessionDetails = loadable(
  () => import("pages/ExamSessionsPage/SessionDetails")
);
const OmrSheets = loadable(() => import("./pages/OmrSheets"));
const Posts = loadable(() => import("./pages/Feed/Posts"));
const PrintTimetable = loadable(
  () => import("./pages/ExamSessionsPage/PrintTimetable")
);
const AnuragCET = loadable(() => import("pages/AnuragCET"));
const Careers = loadable(() => import("pages/Careers"));
const AssessmentRules = loadable(() => import("pages/AssessmentRules"));
const InvigilationReport = loadable(
  () => import("pages/Reports/InvigilationReport")
);
const Invigilations = loadable(() => import("pages/Invigilations"));
const TimeTable = loadable(() => import("pages/TimeTable"));
const StudentMarks = loadable(() => import("pages/Batches/StudentMarks"));
const Rooms = loadable(() => import("pages/Rooms"));
const Memo = loadable(() => import("pages/Memo"));
const RevaluationCompletedReport = loadable(
  () =>
    import("./layouts/ExamsLayout/pages/Revaluation/RevaluationCompletedReport")
);
export interface IRoute {
  component: any;
  exact?: boolean;
  name: string;
  path: string;
  routes?: Array<IRoute>;
}

const routes: Array<IRoute> = [
  {
    component: TRWithMarksBeforeProcessing,
    exact: true,
    name: "TR With Marks",
    path: "/tr-with-marks-before-processing",
  },
  {
    component: SectionWiseAnalysisReport,
    exact: true,
    name: "Section wise analysis report",
    path: "/sectionwise-analysis-report",
  },
  {
    component: TRWithMarks,
    exact: true,
    name: "TR With Marks",
    path: "/tr-with-marks",
  },
  {
    component: Halltickets,
    name: "Hall Tickets",
    path: "/halltickets",
    exact: true,
  },
  {
    component: Memo,
    name: "Memo",
    path: "/memo",
    exact: true,
  },
  {
    component: TSheets,
    name: "TSheets",
    path: "/t-sheets",
    exact: true,
  },
  {
    component: PrintDForm,
    name: "DForm report",
    path: "/d-form-report",
    exact: true,
  },
  {
    component: PrintCourseRegistrations,
    name: "Registrations report",
    path: "/registrations-report",
    exact: true,
  },
  {
    component: AnuragCetAttendanctSheet,
    exact: true,
    name: "AnuragCetAttendanctSheet",
    path: "/anuragcet-attendance-sheets",
  },
  {
    component: ResetPassword,
    exact: true,
    name: "Reset Password",
    path: "/reset-password",
  },
  {
    component: ModerationAnalysis,
    exact: true,
    name: "Moderation Analysis",
    path: "/moderation-analysis",
  },
  {
    component: AdjustedMarksReport,
    exact: true,
    name: "Adjusted Marks Report",
    path: "/adjusted-marks-report",
  },
  {
    component: RevaluationReport,
    exact: true,
    name: "Revaluation Required",
    path: "/revaluation-required",
  },
  {
    component: RevaluationCompletedReport,
    exact: true,
    name: "Revaluation completed",
    path: "/revaluation-completed",
  },
  {
    component: GraftingAnalysis,
    exact: true,
    name: "Grafting Analysis",
    path: "/grafting-analysis",
  },
  {
    component: BeforeModeration,
    exact: true,
    name: "Before Moderation",
    path: "/before-moderation",
  },
  {
    component: OmrSheets,
    exact: true,
    name: "",
    path: "/omr-sheets",
  },
  {
    component: ExamAttendanceSheet,
    exact: true,
    name: "",
    path: "/exam-attendance-sheet",
  },
  {
    component: SeatingPlanNoticeBoard,
    exact: true,
    name: "",
    path: "/seating-plan-notice-board",
  },
  {
    component: ExamSeatingPlan,
    exact: true,
    name: "",
    path: "/exam-seating-plan",
  },
  {
    component: PrintDForm,
    exact: true,
    name: "",
    path: "/exam/:examId/subject/:subjectId",
  },
  {
    component: ExamFeeReceipt,
    exact: true,
    name: "Receipt",
    path: "/exam-fee-receipt/:id",
  },
  {
    component: ReceiptPage,
    exact: true,
    name: "Receipt",
    path: "/fee-receipt/:id",
  },
  {
    component: LoginPage,
    exact: true,
    name: "Login",
    path: "/login",
  },
  {
    component: PrintReport,
    exact: true,
    name: "Print Report",
    path: "/print-report",
  },
  {
    component: PrintExamFeeReport,
    exact: true,
    name: "Print Exam Fee Report",
    path: "/print-exam-fee-report",
  },
  {
    component: PrintTimetable,
    exact: true,
    name: "Print Timetable",
    path: "/print-timetable/:examGroupId",
  },
  {
    component: PrintYellowSheets,
    exact: true,
    name: "Yellow Sheets",
    path: "/print-yellow-sheets/exam-group/:examGroupId/subject/:bundleSubjectId",
  },
  {
    component: PrintYellowSheetsReports,
    exact: true,
    name: "Yellow Sheets Reports",
    path: "/print-yellow-sheets-reports/exam-group/:examGroupId/subject/:bundleSubjectId",
  },
  {
    component: InvigilationReport,
    exact: true,
    name: "Invigilation Report",
    path: "/invigilation-report",
  },
  {
    component: ExamsLayout,
    name: "ExamsLayout",
    path: "/examinations",
    exact: false,
    routes: [],
  },

  {
    component: TheLayout,
    name: "Dashboard",
    path: "/",
    exact: false,
    routes: [
      {
        component: Dashboard,
        exact: true,
        name: "Dashboard",
        path: "/dashboard",
      },
      {
        component: AddSeatingArrangement,
        exact: true,
        name: "Seating Arrangement",
        path: "/seating-plan/add",
      },
      {
        component: SeatingArrangement,
        exact: true,
        name: "Seating Arrangement",
        path: "/seating-plan",
      },
      {
        component: UsersPage,
        exact: true,
        name: "Users",
        path: "/users",
      },
      {
        component: Applications,
        exact: true,
        name: "Applications",
        path: "/applications",
      },
      {
        component: AgriCET,
        exact: true,
        name: "Agricet",
        path: "/agricet",
      },
      {
        component: AssessmentRules,
        exact: true,
        name: "Assessment Rules",
        path: "/assessment-rules",
      },
      {
        component: Careers,
        exact: true,
        name: "Careers",
        path: "/careers",
      },
      {
        component: ViewPHD,
        exact: true,
        name: "Phd view",
        path: "/phd/:id",
      },
      {
        component: LevelOfStudy,
        exact: true,
        name: "Courses",
        path: "/courses",
      },
      {
        component: Phd,
        exact: true,
        name: "Phd",
        path: "/phd",
      },
      {
        component: Clubs,
        exact: true,
        name: "Cubs",
        path: "/clubs",
      },
      {
        component: RolesAndPermissionsPage,
        exact: true,
        name: "Roles & Permissions",
        path: "/roles-and-permissions",
      },
      {
        component: Posts,
        exact: true,
        name: "Posts",
        path: "/student-feed/all-posts",
      },
      {
        component: Feed,
        exact: true,
        name: "Feed",
        path: "/student-feed",
      },
      {
        component: PrintApplication,
        exact: true,
        name: "Print Application",
        path: "/admissions/:id/print",
      },
      {
        component: EditAdmission,
        exact: true,
        name: "Edit Admission",
        path: "/admissions/:id",
      },
      {
        component: AdmissionsPage,
        exact: true,
        name: "Admissions",
        path: "/admissions",
      },
      {
        component: StudentFees,
        exact: true,
        name: "Student Fees",
        path: "/student-fees",
      },
      {
        component: UpdateStudent,
        exact: true,
        name: "Student Details",
        path: "/students/:studentId/edit",
      },
      {
        component: ViewStudent,
        exact: true,
        name: "Student Details",
        path: "/students/:studentId/details",
      },
      {
        component: Students,
        exact: true,
        name: "Students",
        path: "/students",
      },
      {
        component: EventDetails,
        exact: true,
        name: "Event Details",
        path: "/events/:eventId/details",
      },
      {
        component: Events,
        exact: true,
        name: "Events",
        path: "/events",
      },
      {
        component: ManualBundles,
        exact: true,
        name: "ManualBundles",
        path: "/exams/sessions/:examGroupId/subjects/:subjectId/bundles",
      },
      {
        component: BundleCommonSubjects,
        exact: true,
        name: "BundleCommonSubjects",
        path: "/exams/sessions/:examGroupId/subjects",
      },
      {
        component: SeatingArrangement,
        exact: true,
        name: "ExamSessions",
        path: "/exams/sessions/:id/seating-arrangement",
      },
      {
        component: SessionDetails,
        exact: true,
        name: "SessionDetails",
        path: "/exams/sessions/:id/details",
      },
      {
        component: ExamSessions,
        exact: true,
        name: "ExamSessions",
        path: "/exams",
      },
      {
        component: ExamSessions,
        exact: true,
        name: "ExamSessions",
        path: "/exam-sessions",
      },
      {
        component: Bundles,
        exact: true,
        name: "Bundles",
        path: "/exams/:examId/subject/:subjectId/bundles",
      },
      {
        component: ClassRooms,
        exact: true,
        name: "Class Rooms",
        path: "/class-rooms",
      },
      {
        component: AwardMarks,
        exact: true,
        name: "Award Marks",
        path: "/exam-details/:examId/award-marks",
      },

      {
        component: ExamTimetablePage,
        exact: true,
        name: "Exam Timetable",
        path: "/exam-timetable",
      },
      {
        component: Streams,
        exact: true,
        name: "Streams",
        path: "/programs",
      },

      {
        component: Departments,
        exact: true,
        name: "Departments",
        path: "/departments",
      },
      {
        component: Semesters,
        exact: true,
        name: "Semesters",
        path: "/semesters",
      },
      {
        component: CourseRegistrations,
        exact: true,
        name: "Course Registrations",
        path: "/course-registrations",
      },
      {
        component: Curriculums,
        exact: true,
        name: "Curriculums",
        path: "/curriculums",
      },
      {
        component: CurriculumDetailsPage,
        exact: true,
        name: "Curriculum Details",
        path: "/curriculum-details",
      },
      {
        component: Sections,
        exact: true,
        name: "Sections",
        path: "/sections",
      },
      {
        component: CreateSubjectDetails,
        exact: true,
        name: "Create Subject Details",
        path: "/subjects/:subjectId/subject-details/create",
      },
      {
        component: Subjects,
        exact: true,
        name: "Subjects",
        path: "/subjects",
      },
      {
        component: MarksDivision,
        exact: true,
        name: "Marks Divisions",
        path: "/marks-divisions",
      },
      {
        component: StudentSections,
        exact: true,
        name: "StudentSections",
        path: "/student-sections",
      },
      {
        component: FeeStructure,
        exact: true,
        name: "Fee Structure",
        path: "/fee-structure",
      },
      {
        component: StudentMarks,
        exact: true,
        name: "Student marks",
        path: "/batches/marks",
      },
      {
        component: Batches,
        exact: true,
        name: "Batches",
        path: "/batches",
      },
      {
        component: FeeReport,
        exact: true,
        name: "Fee Report",
        path: "/fee-report",
      },
      {
        component: ExamFeePayments,
        exact: true,
        name: "Exam Fee Payments",
        path: "/exam-fee-payments",
      },
      {
        component: ExamFeeReports,
        exact: true,
        name: "Exam Fee Payments",
        path: "/exam-fee-reports",
      },
      {
        component: Scholarship,
        exact: true,
        name: "Scholarship",
        path: "/scholarships",
      },
      {
        component: AuditLogs,
        exact: true,
        name: "Audit Logs",
        path: "/audit-logs",
      },
      {
        component: Reports,
        exact: true,
        name: "Reports",
        path: "/reports",
      },
      {
        component: Invigilations,
        exact: true,
        name: "Invigilations",
        path: "/invigilations",
      },
      {
        component: TimeTable,
        exact: true,
        name: "Time Table",
        path: "/time-table",
      },
      {
        component: Rooms,
        exact: true,
        name: "Rooms",
        path: "/rooms",
      },
    ],
  },
  {
    component: AnuragCET,
    exact: true,
    name: "AnuragCET",
    path: "/anuragcet",
  },
];

export default routes;
