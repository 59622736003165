import {createSlice} from "@reduxjs/toolkit";
import {ofType} from 'redux-observable';
import {switchMap, map, filter} from 'rxjs/operators';
import axios from "utils/axios";
import * as XLSX from 'xlsx';
import moment from 'moment';

interface AdmissionsState {
  data: Array<any>
  error: any
  loading: boolean
  studentFees: Array<any>
  filteredData: Array<any>
}

const initialState: AdmissionsState = {
  data: [],
  error: null,
  loading: true,
  studentFees: [],
  filteredData: [],
};

function isNumber(n) {
  return !isNaN(parseFloat(n)) && !isNaN(n - 0)
}

const studentFeesSlice = createSlice({
  name: 'student-fees',
  initialState,
  reducers: {
    fetchStudentFees(state) {
    },
    fetchingStudentFees(state) {
      state.loading = true;
    },
    fetchedStudentFees(state, action) {
      state.data = action.payload.data;
      state.studentFees = action.payload.admissions;
      state.loading = false;
      state.error = null;
    },
    onSearch(state, action) {
      let value = action.payload.value;
      if (!value) {
        state.studentFees = state.data;
        return;
      }
      
      state.studentFees = state.data.filter(admission => {
        if (isNumber(value)) {
          return `${admission.interHallTicketNumber || ''}`.includes(value.toUpperCase())
        }else if (admission.fullName && admission.fullName.includes(value.toUpperCase())) {
          return true
        } else if (admission.admissionNo && admission.admissionNo.includes(value.toUpperCase())) {
          return true
        }
      });
    },
  }
});

export const {
  fetchStudentFees,
  fetchedStudentFees,
  onSearch,
} = studentFeesSlice.actions

export function fetchStudentFeesEpic(action$) {
  return action$.pipe(
    ofType(fetchStudentFees.type),
    switchMap(async (action: any) => {
      try {
        let res = await axios.get(`/student-fees`);
        return {
          data: res.data,
          admissions: res.data,
        };
      } catch (err) {
        console.log(err);
        alert(`Error occurred: [${err.message}]`)
      }
    }),
    map(data => fetchedStudentFees(data))
  );
}


export default studentFeesSlice.reducer;
