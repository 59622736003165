import Spinner from "components/Spinner";
import useFetch from "hooks/useFetch";
import React, { useEffect } from "react";
import Barcode from "react-barcode";
import { useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import _ from "lodash";
import numWords from "num-words";

const StyledPrintContainer = styled.div`
  width: 25cm;
  height: 33cm;
  border-radius: 5px;
  margin: auto;
  .tablelayout table td {
    padding: 4px 4px;
  }
`;

const StyledYellowSheet = styled.div`
  padding-top: 1cm;
  padding-left: 0.5cm;
  padding-right: 0.5cm;
  /* height: 28.7cm; */

  header .title {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }

  header .caption,
  header .address {
    text-align: center;
  }

  header .exam-details {
    text-transform: uppercase;
    text-align: center;
  }

  table {
    width: 100%;
    text-align: center;
  }

  table td,
  table th {
    border: 1px solid black;
    padding: 8px 4px;
  }

  footer {
    margin-top: 1rem;
    padding: 8px;
    border: 1px solid black;
  }

  @media print {
    page-break-after: always;
  }
`;

function PrintYellowSheetsReports() {
  const match: any = useRouteMatch<any>(
    "/print-yellow-sheets-reports/exam-group/:examGroupId/subject/:subjectCode"
  );
  const { loading, data } = useFetch(
    `bundle-subjects/yellow-sheets-reports?subjectCode=${match.params.subjectCode}&examGroupId=${match.params.examGroupId}`
  );
  useEffect(() => {
    if (!loading && data.data.length) {
      // window.print()
    }
  }, [loading]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <StyledPrintContainer>
      {data.data.map((item, index) => {
        let grandTotal = [];
        grandTotal = item.studExternals
          .reduce((sum, item) => sum + item.marks, 0)
          .toString()
          .split("");
        if (grandTotal.length == 3) {
          grandTotal = [0].concat(grandTotal);
        } else if (grandTotal.length == 2) {
          grandTotal = [0, 0].concat(grandTotal);
        } else if (grandTotal.length == 1) {
          grandTotal = [0, 0, 0].concat(grandTotal);
        }
        let omrs = [];
        let marksData = [];
        for (let i = 0; i < 50; i++) {
          if (item.studExternals[i]?.slno == i + 1) {
            omrs.push({
              ...item.studExternals[i],
              order: getOrder(item.studExternals[i]?.slno),
              slNo: item.studExternals[i]?.slno
                ? item.studExternals[i]?.slno
                : i + 1,
            });
          } else {
            omrs.push({
              ...item.studExternals[i],
              order: getOrder(item.studExternals[i]?.slno ?? i + 1),
              slNo: item.studExternals[i]?.slno
                ? item.studExternals[i]?.slno
                : i + 1,
            });
          }
        }
        marksData = _.chunk(
          omrs.sort((a, b) => a.slNo - b.slNo),
          10
        );
        omrs = _.chunk(
          omrs.sort((a, b) => a.order - b.order),
          5
        );

        function getOrder(slno) {
          let order;
          for (let i = 0; i < 10; i++) {
            if (slno % 10 == 0) {
              order = 10;
            }
            if (slno % 10 == i) {
              order = i;
            }
          }
          return order;
        }
        return (
          <StyledYellowSheet key={index}>
            <header>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingBottom: "4px",
                  marginBottom: "4px",
                }}
              >
                <div
                  style={{
                    border: "2px solid black",
                    minWidth: 200,
                    width: "auto",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <div style={{ marginTop: "4px" }}>
                      <Barcode
                        margin={0}
                        height={30}
                        width={1.5}
                        displayValue={false}
                        value={item.bundleSerialNo}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ textAlign: "center" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <img
                        src={data.school.logoSquare}
                        style={{ height: "2rem" }}
                      />
                    </div>
                    <div
                      style={{ marginLeft: "0.5rem", marginRight: "0.5rem" }}
                    >
                      <div style={{ fontSize: "20px" }}>
                        <b>{data.school.name}</b>
                      </div>
                    </div>
                  </div>
                  <div className="caption">Autonomous</div>
                  <div className="address">{data.school.address}</div>
                  <h3>
                    <u>AWARD LIST</u>
                  </h3>
                </div>
                <div
                  style={{
                    border: "2px solid black",
                    width: 200,
                    padding: "12px",
                    textAlign: "center",
                  }}
                >
                  <div>Bundle No.</div>
                  <div style={{ fontSize: 18, fontWeight: "bold" }}>
                    {item.bundleSerialNo}
                  </div>
                </div>
              </div>
            </header>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h3>Course: {data.course.courseName}</h3>
              <h3>
                Year & Semester: <span>{data.semester.name}</span>
              </h3>
              <h3>Regular/Supply</h3>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h4 style={{ width: 300 }}>
                Branch: {data.branchCode.split(",").join("/")}
              </h4>
              <h3>Subject Name: {data.subject.name}</h3>
              <h3>Regulation: {data.curriculum}</h3>
              <h3>Question Paper Code:</h3>
            </div>
            <h3 style={{ textAlign: "center" }}>
              <u>To be filled by Evaluator:</u>
            </h3>
            <div className="tablelayout" style={{ paddingBottom: 8 }}>
              <table>
                <tr>
                  <th colSpan={2}>Column - 1</th>
                  <th colSpan={2}>Column - 2</th>
                  <th colSpan={2}>Column - 3</th>
                  <th colSpan={2}>Column - 4</th>
                  <th colSpan={2}>Column - 5</th>
                </tr>
                <tr>
                  {[1, 2, 3, 4, 5].map((item, index) => (
                    <React.Fragment key={index}>
                      <td>S.No</td>
                      <td>Marks</td>
                    </React.Fragment>
                  ))}
                </tr>
                {omrs.map((item, index) => (
                  <tr key={index}>
                    {item.map((omr, omrIndex) => (
                      <React.Fragment key={omrIndex}>
                        <td>{omr.slNo || omr.order}</td>
                        <td>{omr?.marks == null ? "-" : omr?.marks}</td>
                      </React.Fragment>
                    ))}
                  </tr>
                ))}
                <tr key={index}>
                  {marksData.map((item, index) => (
                    <>
                      <th>Total</th>
                      <td>
                        {item?.reduce(
                          (sum, item) => sum + (item.marks || 0),
                          0
                        )}
                      </td>
                    </>
                  ))}
                </tr>
                <tr>
                  <th rowSpan={2} colSpan={4}>
                    Grand Total
                  </th>
                  <th colSpan={2}>In numerics</th>
                  <td>{grandTotal[0]}</td>
                  <td>{grandTotal[1]}</td>
                  <td>{grandTotal[2]}</td>
                  <td>{grandTotal[3]}</td>
                </tr>
                <tr>
                  <th colSpan={2}>In words</th>
                  <td>{_.startCase(numWords(grandTotal[0]))}</td>
                  <td>{_.startCase(numWords(grandTotal[1]))}</td>
                  <td>{_.startCase(numWords(grandTotal[2]))}</td>
                  <td>{_.startCase(numWords(grandTotal[3]))}</td>
                </tr>
              </table>
            </div>
            <div
              style={{
                border: "1px solid black",
                textAlign: "center",
                padding: 8,
              }}
            >
              <u>
                <h3>To be filled by Scrutinizer:</h3>
              </u>
              <div>
                <div>
                  <table>
                    <colgroup>
                      <col width="10%" />
                      <col width="10%" />
                      <col width="10%" />
                      <col width="10%" />
                      <col width="10%" />
                      <col width="10%" />
                      <col width="10%" />
                      <col width="10%" />
                      <col width="10%" />
                      <col width="10%" />
                    </colgroup>

                    <tr>
                      {[1, 2, 3, 4, 5].map((item) => (
                        <React.Fragment key={item}>
                          <th>Column {item} Correction</th>
                          <td></td>
                        </React.Fragment>
                      ))}
                    </tr>
                    <tr>
                      {[1, 2, 3, 4, 5].map((item) => (
                        <React.Fragment key={item}>
                          <th>Total</th>
                          <td></td>
                        </React.Fragment>
                      ))}
                    </tr>
                  </table>
                </div>
              </div>
              <div style={{ paddingTop: 4 }}>
                <table>
                  <tr>
                    <th rowSpan={2}>Grand Total</th>
                    <th colSpan={6}>In numerics</th>
                    <td>{grandTotal[0]}</td>
                    <td>{grandTotal[1]}</td>
                    <td>{grandTotal[2]}</td>
                    <td>{grandTotal[3]}</td>
                  </tr>
                  <tr>
                    <th colSpan={6}>In words</th>
                    <td>{_.startCase(numWords(grandTotal[0]))}</td>
                    <td>{_.startCase(numWords(grandTotal[1]))}</td>
                    <td>{_.startCase(numWords(grandTotal[2]))}</td>
                    <td>{_.startCase(numWords(grandTotal[3]))}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div style={{ paddingTop: 8 }}>
              <table>
                <tr>
                  <td style={{ padding: "20px 10px", textAlign: "start" }}>
                    <div style={{ paddingBottom: 10 }}>
                      Name of the Scrutinizer
                    </div>
                    <div>Department:</div>
                  </td>
                  <td style={{ padding: "20px 10px", textAlign: "center" }}>
                    Signature with date
                  </td>
                </tr>
              </table>
            </div>
            <div style={{ borderBottom: "dotted", paddingTop: 24 }} />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingTop: 12,
              }}
            >
              <div
                style={{
                  border: "2px solid black",
                  minWidth: 200,
                  width: "auto",
                  textAlign: "center",
                }}
              >
                <div>
                  <div>Barcode</div>
                  <div style={{ marginTop: "4px" }}>
                    <Barcode
                      margin={0}
                      height={30}
                      width={1.5}
                      displayValue={false}
                      value={item.bundleSerialNo}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  border: "2px solid black",
                  width: 200,
                  padding: "8px",
                  textAlign: "center",
                }}
              >
                <div>Bundle No.</div>
                <div style={{ fontSize: 18, fontWeight: "bold" }}>
                  {item.bundleSerialNo}
                </div>
              </div>
            </div>
            <div style={{ paddingTop: 4 }}>
              <table>
                <tr>
                  <td style={{ padding: "20px 10px", textAlign: "start" }}>
                    <div style={{ paddingBottom: 10 }}>
                      Name & Designation of the Evaluator
                    </div>
                  </td>
                  <td style={{ padding: "20px 10px", textAlign: "center" }}>
                    Signature with date:
                  </td>
                </tr>
              </table>
            </div>
          </StyledYellowSheet>
        );
      })}
    </StyledPrintContainer>
  );
}

export default PrintYellowSheetsReports;
