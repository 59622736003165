import { createSlice } from "@reduxjs/toolkit";
import { ofType } from "redux-observable";
import { map, switchMap } from "rxjs/operators";
import axios from "utils/axios";

interface UsersState {
  data: Array<any>;
  invigilations: Array<any>;
  error: any;
  loading: boolean;
}

const initialState: UsersState = {
  data: [],
  invigilations: [],
  error: null,
  loading: true,
};

const invigilationsSlice = createSlice({
  name: "invigilations",
  initialState,
  reducers: {
    fetchInvigilations(state) {},
    fetchingInvigilations(state) {
      state.loading = true;
    },
    fetchedInvigilations(state, action) {
      state.data = action.payload.data;
      state.invigilations = action.payload.data;
      state.loading = false;
      state.error = null;
    },
    onSearch(state, action) {
      if (!action.payload.value) {
        state.invigilations = state.data;
        return;
      }
      state.invigilations = state.data.filter((item) => {
        return item.title
          .toUpperCase()
          .includes(action.payload.value.toUpperCase());
      });
    },
  },
});

export const { fetchedInvigilations, fetchInvigilations, onSearch } =
  invigilationsSlice.actions;

export function fetchInvigilationsEpic(action$) {
  return action$.pipe(
    ofType(fetchInvigilations.type),
    switchMap(async (action: any) => {
      try {
        let res = await axios.get(`/invigilation`);
        console.log(res.data);
        return {
          data: res.data,
        };
      } catch (err) {
        console.log(err);
        alert(`Error occurred: [${err.message}]`);
      }
    }),
    map((data) => fetchedInvigilations(data))
  );
}

export default invigilationsSlice.reducer;
