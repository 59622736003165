import Spinner from "components/Spinner";
import useFetch from "hooks/useFetch";
import React from "react";
import _ from "lodash";
import styled from "styled-components";
import { StyledTableContainer } from "components/StyledTableContainer";

const StyledExamAttendanceSheet = styled.div`
  padding-top: 1cm;
  padding-left: 0.5cm;
  padding-right: 0.5cm;
  /* height: 28.7cm; */

  header .title {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }

  header .caption,
  header .address {
    text-align: center;
  }

  header .exam-details {
    text-transform: uppercase;
    text-align: center;
  }

  table.attendance {
    width: 100%;
  }

  table.attendance td,
  table.attendance th {
    border: 1px solid black;
    padding: 2px 8px;
  }

  footer {
    margin-top: 1rem;
    padding: 8px;
    border: 1px solid black;
  }

  @media print {
    page-break-after: always;
  }
`;
export default function RevaluationReport() {
  const urlParams = new URLSearchParams(window.location.search);
  const courseId = urlParams.get("courseId");
  const regulationId = urlParams.get("regulationId");
  const semNo = urlParams.get("semNo");
  const monthYear = urlParams.get("monthYear");

  const { data, loading } = useFetch(
    `/revaluation/reval3-report?courseId=${courseId}&regulationId=${regulationId}&semNo=${semNo}&monthYear=${monthYear}`
  );

  if (loading) {
    return <Spinner />;
  }
  const newData = _.groupBy(data.data, "branch_code");
  const groupByBranchCode = Object.keys(newData).map((item) => ({
    branchCode: item,
    evaluations: Object.keys(_.groupBy(newData[item], "subject_code")).map(
      (subjectCode) => ({
        subjectName: _.groupBy(newData[item], "subject_code")[subjectCode][0]
          .subject_name,
        subjectCode: subjectCode,
        students: _.groupBy(newData[item], "subject_code")[subjectCode],
      })
    ),
  }));

  console.log(groupByBranchCode);

  return (
    <div
      style={{
        width: "25cm",
        height: "33cm",
        borderRadius: "5px",
        margin: "auto",
      }}
    >
      return (
      <div>
        {groupByBranchCode.map((branch: any, index) => {
          // const item = students[0];
          return (
            <StyledExamAttendanceSheet>
              <header>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderBottom: "1px solid black",
                    paddingBottom: "4px",
                    marginBottom: "4px",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <img src={data.school?.logo} style={{ height: "2rem" }} />
                    <div style={{ marginTop: "8px" }} className="address">
                      {data.school?.address}
                    </div>
                  </div>
                </div>

                <div className="exam-details">
                  <h3>Revaluation Required Report</h3>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>Exam: {data.examGroup.groupName}</div>
                  <div> Held on: {data.examGroup.monthYear}</div>
                </div>
                <div>
                  <div>Branch: {branch.branchCode}</div>
                </div>
              </header>

              {branch.evaluations.map((evaluation, item) => (
                <div style={{ paddingBottom: 16 }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: 8,
                    }}
                  >
                    <div>Subject: {evaluation.subjectName}</div>
                    <div>Subject code: {evaluation.subjectCode}</div>
                  </div>
                  <StyledTableContainer>
                    <table>
                      <thead>
                        <tr>
                          <th rowSpan={2}>S.No</th>
                          <th rowSpan={2}>Bundle No</th>
                          <th rowSpan={2}>Bundle S.No</th>
                          {["Original Marks", "Validation Marks"].map(
                            (item, index) => (
                              <th style={{ textAlign: "center" }} colSpan={3}>
                                {item}
                              </th>
                            )
                          )}
                        </tr>
                        <tr>
                          <th>Int</th>
                          <th>Ext</th>
                          <th>Grade</th>
                          <th>I</th>
                          <th>II</th>
                          <th>III</th>
                        </tr>
                      </thead>
                      <tbody>
                        {evaluation.students.map((student, index) => (
                          <tr>
                            <th>{index + 1}</th>
                            <th>{student.bundle_serial_no}</th>
                            <th>{student.slno}</th>
                            <th>{student.intMarks}</th>
                            <th>{student.extMarks}</th>
                            <th>{}</th>
                            <th>{student.reval1}</th>
                            <th>{student.reval2}</th>
                            <th>{}</th>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </StyledTableContainer>
                </div>
              ))}
            </StyledExamAttendanceSheet>
          );
        })}
      </div>
    </div>
  );
}
