import React from 'react';
import { Breadcrumb, Space } from 'antd';
import { useHistory, Link } from 'react-router-dom'

export default function CustomBreadcrumb(props) {
  return (
    <div style={{ display: "flex", alignItems: 'center', marginBottom: 16 }}>
      <Space>
        <Breadcrumb>
          {
            props.links.map((link, index) => (
              <Breadcrumb.Item key={index}>
                <Link to={link.path ?? ''}>{link.name}</Link>
              </Breadcrumb.Item>
            ))
          }
        </Breadcrumb>
      </Space>
    </div>
  )
}