import styled from "styled-components";

export const StyledTableContainer = styled.div`
  /* background: white; */
  border-radius: 16px;

  table thead tr {
    background-color: #efefef;
  }

  table,
  td,
  th {
    border: 1px solid #bebebe;
    padding: 8px;
  }

  table {
    background: white;
    width: 100%;
    border-collapse: collapse;
  }

  @media print {
    table,
    td,
    th {
      border: 1px solid black;
      padding: 8px;
    }
  }
`;
