import {createSlice} from "@reduxjs/toolkit";
import axios from "utils/axios";
import {ofType} from 'redux-observable';
import {switchMap, map, delay, debounce} from 'rxjs/operators';
import {message} from "antd";

export interface AssessmentRulesState {
  loading: boolean;
  data: any,
  error: any,
  courseId: number,
  regulationId: number,
  subjectTypes: SubjectTypes[]
}

export interface SubjectTypes {
  id?: number,
  type: string,
  assessmentRules: AssessmentRules[]
}

export interface AssessmentRules {
  regulation_id: number,
  subject_type: string,
  assessment_type_name: string,
  id?: number,
  course_id: number,
  assessment_type_id: number,
  subject_type_id: number,
  weightage: number,
  max_marks: number,
  pass_percentage: number,
  assessments_conducted: number,
  assessments_graded: number,
  score_calculation_type: string,
  isNew: boolean,
  isEditing: boolean,
  isLoading: boolean,
}

const initialState: AssessmentRulesState = {
  loading: true,
  courseId: null,
  regulationId: null,
  data: [],
  error: null,
  subjectTypes: [],
}

const assessmentsSlice = createSlice({
  name: 'assessmentRules',
  initialState: initialState,
  reducers: {
    fetchAssessmentRules(state, action: any) {
      state.loading = true;
    },
    fetchedAssessmentRules(state, action: any) {
      state.loading = false;
      state.data = action.payload.data;
      // state.subjectTypes = action.payload.data
      state.subjectTypes = action.payload.data.map(subjectType => ({
        ...subjectType,
        assessmentRules: subjectType.assessmentRules.map(assessmentRule => ({
          ...assessmentRule,
          isEditing: false,
          isNew: false
        }))
      }));
    },
    updateValue(state, action: any) {
      let index = action.payload.index;
      let key = action.payload.key;
      let value = action.payload.value;
      state.subjectTypes[action.payload.subjectTypeIndex].assessmentRules[index][key] = value;
    },
    updateCourseId(state, action: any) {
      state.courseId = action.payload;
    },
    updateRegulationId(state, action: any) {
      state.regulationId = action.payload;
    },
    addAssessment(state, action) {
      state.subjectTypes[action.payload.subjectTypeIndex].assessmentRules =
        state.subjectTypes[action.payload.subjectTypeIndex].assessmentRules.filter(f => f);
      state.subjectTypes[action.payload.subjectTypeIndex].assessmentRules = [
        ...state.subjectTypes[action.payload.subjectTypeIndex].assessmentRules,
        {
          regulation_id: 1,
          subject_type: '',
          assessment_type_name: '',
          course_id: null,
          assessment_type_id: null,
          subject_type_id: null,
          weightage: null,
          max_marks: null,
          pass_percentage: null,
          assessments_conducted: null,
          assessments_graded: null,
          score_calculation_type: '',
          isNew: true,
          isEditing: true,
          isLoading: false,
        }
      ]
    },
    removeAssessment(state, action) {
      delete state.subjectTypes[action.payload.subjectTypeIndex].assessmentRules[action.payload.index]
    },
    editAssessment(state, action) {
      state.subjectTypes[action.payload.subjectTypeIndex].assessmentRules[action.payload.index].isEditing = true;
    },
    updateAssessment(state, action) {
      state.subjectTypes[action.payload.subjectTypeIndex].assessmentRules[action.payload.index].isLoading = true;
    },
    updatedAssessment(state, action) {
      state.subjectTypes[action.payload.subjectTypeIndex].assessmentRules[action.payload.index].isLoading = false;
      // state.subjectTypes[action.payload.subjectTypeIndex].assessmentRules[action.payload.index].isEditing = false;
    },
    saveAssessment(state, action) {
      state.subjectTypes[action.payload.subjectTypeIndex].assessmentRules[action.payload.index] = action.payload.data;
    },
    onCancel(state, action) {
      state.subjectTypes[action.payload.subjectTypeIndex].assessmentRules[action.payload.index].isEditing = false;
      state.subjectTypes[action.payload.subjectTypeIndex].assessmentRules[action.payload.index] = state.data[action.payload.subjectTypeIndex].assessmentRules[action.payload.index]
    },
    clearState(state, action) {
      state.loading = false;
      state.courseId = null;
      state.regulationId = null;
      state.data = [];
      state.error = null;
      state.subjectTypes = []
    }
  }
});

export function fetchAssessmentRulesEpic(action$, state$) {
  return action$.pipe(
    ofType(fetchAssessmentRules.type),
    switchMap(async (action: any) => {
      const state = state$.value.assessmentRules
      const res = await axios.get(`/assessment-rules`, {
        params: {
          courseId: state.courseId,
          regulationId: state.regulationId
        }
      });
      return {
        data: res.data,
      }
    }),
    map(fetchedAssessmentRules),
  );
}

export function updateAssessmentEpic(action$, state$) {
  return action$.pipe(
    ofType(updateAssessment.type),
    switchMap(async (action: any) => {
      console.log(state$)
      console.log(action)
      const state = state$.value.assessmentRules
      const subjectTypeIndex = action.payload.subjectTypeIndex
      const index = action.payload.index
      const data = state.subjectTypes[subjectTypeIndex].assessmentRules[index];
      const url = `assessment-rules`
      const res = await axios.put(`${url}/${data.id}`, data)
      message.success('Updated assessment rule')
      return {
        subjectTypeIndex: subjectTypeIndex,
        index: index
      }
    }),
    map(updatedAssessment),
  );
}

const {fetchedAssessmentRules} = assessmentsSlice.actions;

export const {
  updatedAssessment,
  fetchAssessmentRules, updateAssessment,
  updateValue, onCancel, saveAssessment,
  addAssessment, removeAssessment,
  editAssessment, updateCourseId,
  updateRegulationId, clearState
} = assessmentsSlice.actions
export default assessmentsSlice.reducer;