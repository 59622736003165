import { Store } from 'pullstate'

export interface IErrorStore {
  error: string;
  statusCode: number;
  data: any;
  message: {
    heading: string;
    description: string;
  }
}

export const ErrorStore = new Store<IErrorStore>({
  error: null,
  statusCode: null,
  data: {},
  message: {
    heading: '',
    description: '',
  }
})