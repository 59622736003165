import { Col, Row } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useState } from "react";
import styled from "styled-components";
import Spinner from "components/Spinner";
import useFetch from "hooks/useFetch";

export const StyledTableContainer = styled.div`
  background: white;
  border-radius: 16px;
  table thead tr {
    background-color: #efefef;
  }
  table,
  td,
  th {
    border: 1px solid #bebebe;
    padding: 8px;
    text-align: center;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
  }
`;

const StyledSeatingPlanNoticeBoard = styled.div`
  padding-top: 1cm;
  padding-left: 0.5cm;
  padding-right: 0.5cm;
  /* height: 28.7cm; */

  header .title {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }

  header .caption,
  header .address {
    text-align: center;
  }

  header .exam-details {
    text-transform: uppercase;
    text-align: center;
  }

  table.attendance {
    width: 100%;
  }

  /* table {
    page-break-after: always;
  } */

  table.attendance td,
  table.attendance th {
    border: 1px solid black;
    padding: 8px 4px;
  }

  footer {
    margin-top: 1rem;
    padding: 8px;
    border: 1px solid black;
  }

  @media print {
    page-break-after: always;
  }
`;

export default function PrintCourseRegistrations() {
  const urlParams = new URLSearchParams(window.location.search);
  const examGroupId = urlParams.get("examGroupId");
  //   const paper = urlParams.get("paper");
  const [title, setTitle] = useState("");

  const { loading, data } = useFetch(
    `/bundle-subjects/course-registrations?examGroupId=${examGroupId}`
  );

  React.useEffect(() => {
    if (!loading) {
      let title = `${data.examGroup.groupName} Course Registrations`;
      document.title = title;
      setTitle(title);
    }
  }, [loading]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div
      style={{
        width: "25cm",
        height: "33cm",
        borderRadius: "5px",
        margin: "auto",
        background: "white",
      }}
    >
      <div>
        <StyledSeatingPlanNoticeBoard>
          <React.Fragment>
            <header>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderBottom: "1px solid black",
                  paddingBottom: "4px",
                  marginBottom: "4px",
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <img src={data.school?.logo} style={{ height: "3rem" }} />
                  <div
                    className="address"
                    style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}
                  >
                    {data.school?.address}
                  </div>
                </div>
              </div>

              <div className="exam-details">
                <div>
                  <b>{title}</b>
                </div>
              </div>
            </header>

            <div style={{ paddingBottom: "24px", paddingTop: "1rem" }}>
              <StyledTableContainer>
                <table>
                  <thead>
                    <tr>
                      <th>Subject Name</th>
                      <th>Subject Code</th>
                      <th>Registrations</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.data.map((item, index) => (
                      <tr>
                        <td>{item.subject.subjectCode}</td>
                        <td>{item.subject.subjectName}</td>
                        <td>{item.registrations}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </StyledTableContainer>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "24px",
                }}
              >
                <div>
                  <h4 style={{ display: "inline-block", textAlign: "center" }}>
                    <div>Sd/-</div>

                    <div>Controller of Examinations</div>
                  </h4>
                </div>
              </div>
            </div>
          </React.Fragment>
        </StyledSeatingPlanNoticeBoard>
      </div>
    </div>
  );
}
