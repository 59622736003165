import { useState, useEffect } from 'react';
import axios from 'utils/axios';

export function useMockFetch(promiseFn: Function) {
  const [data, setData] = useState<any>();
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true)
    promiseFn()
      .then(res => {
        setData(res.data)
        setLoading(false)
      }).catch(err => {
        setLoading(false);
        setError(null)
      })
  }, []);

  return {
    data, loading
  };
}

export default function useFetch(url: string) {
  const [data, setData] = useState<any>();
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true)
    axios.get(url)
      .then(res => {
        setData(res.data)
        setLoading(false)
      }).catch(err => {
        setError(err)
        setLoading(false);
      })
  }, []);

  return {
    data, loading, error
  };
}