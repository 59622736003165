import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Typography, Row, Col, Card, Space, Alert } from 'antd';
import { UserOutlined, LockOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import axios from 'axios';
import './LoginPage.css'
import ErrorBox from 'components/ErrorBox'
import { AssetsStore } from 'shared-state/AssetsStore';
import useFetch from 'hooks/useFetch';
import Spinner from 'components/Spinner';

interface ICredentials {
  username: string,
  password: string
}

const LoginPage = () => {
  const { loading, data: assets } = useFetch('/assets')
  useEffect(() => {
    if (localStorage.getItem('token')) {
      window.location.replace('/');
    }
  })

  if (loading) {
    return (
      <Spinner />
    )
  }

  return (
    <div id="LoginPage">
      <Row className="main-row">
        <Col lg={12} xs={0} className="left-content">
          <Row justify="center" align="middle" className="background-with-image" style={{ height: 'inherit' }}>
            <Col>
              {/* <img style={{ width: '240px' }} src={logoWhite} /> */}
            </Col>
          </Row>
        </Col>

        <Col lg={12} sm={24} xs={24} className="right-content">
          <Row justify="center" align="middle" style={{ height: 'inherit' }}>
            <Col lg={12} xs={20}>
              <Space direction="vertical" style={{ width: '100%' }} size={32}>
                <div style={{ textAlign: 'center' }}>
                  <img src={assets.logo} style={{
                    width: '200px',
                  }} />
                  <div style={{ marginTop: '16px' }}>
                    <Typography.Title level={3} style={{ textAlign: 'center' }}>UMS</Typography.Title>
                  </div>
                </div>
                <Typography.Title level={4} style={{ textAlign: 'center' }}>Sign in to your account</Typography.Title>
                <div>
                  <LoginForm />
                </div>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
};

function LoginForm() {
  const [state, setState] = useState<any>({
    loading: false,
    error: null
  })

  const onFinish = (values: ICredentials) => {
    setState({
      loading: true,
      error: null
    });
    axios.post(`${process.env.REACT_APP_API_HOST}/auth/login`, values).then(res => {
      localStorage.setItem('token', res.data.access_token);
      localStorage.setItem('schoolId', '1');
      window.location.reload();
    }).catch(err => {
      console.log(err)
      setState({
        loading: false,
        error: err
      })
    })
  };

  return (
    <div>
      <ErrorBox err={state.error} />
      <Form
        layout="vertical"
        initialValues={{
          username: '',
          password: ''
        }}
        onFinish={onFinish}
      >
        <Form.Item
          name="username"
          rules={[{ required: true, message: 'Please input your Username!' }]}
        >
          <Input size="large" prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your Password!' }]}
        >
          <Input.Password
            size="large"
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </Form.Item>
        <Form.Item>
          <Button size="large" block loading={state.loading} type="primary" htmlType="submit">
            Log in
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default LoginPage;