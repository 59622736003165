import styled from "styled-components";

const secondaryColor = "#233659";
const primaryColor = "#c02633";

export const Table = styled.table`
  background: white;
  border: 1px solid #dfdfdf;
  padding: ${(props: any) => props.cellPadding || "4px 8px"};
  border-collapse: collapse;
  box-shadow: 0 3px 15px rgb(39 59 128 / 20%);
  thead {
    /* background-color: #eaeff0; */
    background-color: ${secondaryColor};
    color: white;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }

  thead tr th {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  tbody tr:hover {
    background-color: #f9f9f9;
  }

  td {
    /* border: 1px solid #dfdfdf; */
    border: 1px solid #dfdfdf;
  }

  td,
  th {
    padding: ${(props: any) => props.cellPadding || "4px 8px"};
  }

  tbody tr:nth-child(even) {
    /* background: #f4f4f4; */
    /* background: #fafcff; */
  }
`;
