import { createSlice } from "@reduxjs/toolkit";
import { ofType } from "redux-observable";
import { switchMap, map, filter } from "rxjs/operators";
import axios from "utils/axios";
import * as XLSX from "xlsx";
import serverErrorDialog from "../../components/serverErrorDialog";
import moment from "moment";

interface InternalMarksState {
  data: Array<any>;
  reasons: Array<any>;
  error: any;
  loading: boolean;
  subjectId: number;
  monthYear: string;
  semNo: number;
  courseId: number;
  branchCode: string;
  showImportMarksFromExcelSheetDialog: boolean,
  importingInternalMarks: boolean,
}

const initialState: InternalMarksState = {
  data: [],
  reasons: [],
  error: null,
  loading: false,
  subjectId: null,
  monthYear: null,
  semNo: null,
  courseId: null,
  branchCode: null,
  showImportMarksFromExcelSheetDialog: false,
  importingInternalMarks: false,
};

const internalMarksSlice = createSlice({
  name: "internalMarks",
  initialState,
  reducers: {
    fetchInternalMarks(state, action) {
      state.loading = true;
      state.subjectId = action.payload.subjectId;
      state.semNo = action.payload.semNo;
      state.courseId = action.payload.courseId;
      state.branchCode = action.payload.branchCode;
    },
    showImportMarksFromExcelSheetDialog(state, action) {
      state.showImportMarksFromExcelSheetDialog = action.payload;
    },
    importingInternalMarks(state, action) {
      state.importingInternalMarks = action.payload;
    },
    fetchedInternalMarks(state, action) {
      state.data = action.payload.data;
      state.loading = false;
      state.error = null;
    },
    fetchedUpdateReasons(state, action) {
      state.reasons = action.payload.data;
      state.loading = false;
      state.error = null;
    },
    updateMarks(state, action) {
      state.data[action.payload.index][action.payload.key] =
        action.payload.value;
    },
    updateAbsent(state, action) {
      if (action.payload.value == 1) {
        state.data[action.payload.index]["marks"] = 0;
      }
      state.data[action.payload.index][action.payload.key] =
        action.payload.value;
    },
    clearState(state, action) {
      state.data = [];
    },
  },
});

export const {
  fetchInternalMarks,
  fetchedInternalMarks,
  updateMarks,
  updateAbsent,
  clearState,
  fetchedUpdateReasons,
  showImportMarksFromExcelSheetDialog,
  importingInternalMarks,
} = internalMarksSlice.actions;

export function fetchStudentInternalMarksEpic(action$, state$) {
  return action$.pipe(
    ofType(fetchInternalMarks.type),
    switchMap(async (action: any) => {
      try {
        let res = await axios.get(`/stud-internal`, {
          params: {
            subjectId: action.payload.subjectId,
            batch: action.payload.batch,
            branchCode: action.payload.branchCode,
          },
        });
        return {
          data: res.data,
        };
      } catch (err) {
        serverErrorDialog({ error: err });
        return {
          data: [],
        };
      }
    }),
    map((data) => fetchedInternalMarks(data))
  );
}

export function fetchInternalMarksReasonsEpic(action$, state$) {
  return action$.pipe(
    ofType(fetchInternalMarks.type),
    switchMap(async (action: any) => {
      try {
        let res = await axios.get(`/update-reason`, {
          params: {
            type: 'internal',
            subjectId: action.payload.subjectId,
            batch: action.payload.batch,
            branchCode: action.payload.branchCode,
          },
        });
        return {
          data: res.data,
        };
      } catch (err) {
        serverErrorDialog({ error: err });
        return {
          data: [],
        };
      }
    }),
    map((data) => fetchedUpdateReasons(data))
  );
}

export default internalMarksSlice.reducer;
