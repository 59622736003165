import React from 'react';
import _ from 'lodash';
import { Alert } from 'antd';

export default function ErrorBox({ err }) {
  if (!err) {
    return null;
  }

  if (err.response) {
    console.log(err.response.status)

    if (err.response.status == 401) {
      return (
        <Alert
        style={{marginBottom:12}}
          message={"Error"}
          description={_.get(err, 'response.data.message', 'Server error!')}
          type="error"
          showIcon
        />
      )
    }
    if (err.response.status == 422) {
      return (
        <Alert
          style={{marginBottom:12}}
          message={"Error"}
          description={_.get(err, 'response.data.message', 'Server error!')}
          type="error"
          showIcon
        />
      )
    }
  

    return (
      <div>
        <Alert
        style={{marginBottom:12}}
          message={"Error"}
          description={'Unknown error'}
          type="error"
          showIcon
        />
      </div>
    )
  }
}