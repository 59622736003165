import { Row } from "antd";
import Spinner from "components/Spinner";
import _ from "lodash";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import axios from "utils/axios";
import { Header } from "./Header";

const StyledContainer = styled.div`
  padding-top: 1cm;
  padding-left: 0.5cm;
  padding-right: 0.5cm;
  /* height: 28.7cm; */

  header .title {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }

  header .caption,
  header .address {
    text-align: center;
  }

  header .exam-details {
    text-transform: uppercase;
    text-align: center;
  }

  table {
    width: 100%;
    margin-bottom: 10px;
  }

  table td,
  table th {
    border: 1px solid #9c9c9c;
    padding: 8px 8px;
  }

  footer {
    margin-top: 1rem;
    padding: 8px;
    border: 1px solid black;
  }

  @media print {
    page-break-after: always;
    margin-left: 0.5cm;
    margin-right: 0.5cm;
  }
`;

export default function BranchwiseResultsReport() {
  const { isLoading, data } = useQuery("before-moderation", () => {
    const params = new URLSearchParams(window.location.search);
    return axios
      .get(`/t-sheets`, {
        params: {
          monthYear: params.get("monthYear"),
          courseId: params.get("courseId"),
          semNo: params.get("semNo"),
          regulation: params.get("regulation"),
        },
      })
      .then((res) => res.data);
  });

  useEffect(() => {
    if (data) {
      document.title = data.title;
    }
  }, [data]);

  if (isLoading) {
    return <Spinner />;
  }

  console.log(data);

  return (
    <div
      style={{
        width: "25cm",
        // height: "33cm",
        borderRadius: "5px",
        margin: "auto",
        background: "white",
      }}
    >
      {data.results.map((branch, branchIndex) => {
        let chunkLength = 3;
        let chunks = _.chunk(branch?.data, chunkLength);
        return (
          <>
            {chunks.map((grades: any, gradeIndex) => (
              <div key={gradeIndex}>
                <StyledContainer>
                  <Header
                    schoolName={data.school.name}
                    logo={data.school.logoSquare}
                    address={data.school.address}
                    branchCode={branch.branchCode}
                    semester={data.semester}
                    course={data.course.courseName}
                    monthYear={data.monthYear}
                    regulation={data.regulation}
                    title={data.title}
                  />

                  <div>
                    {grades.map((student, studentIdex) => (
                      <table key={studentIdex}>
                        <thead>
                          <tr>
                            <th>HT No.</th>
                            <th>Course Code</th>
                            <th>Course</th>
                            <th>Grade</th>
                            <th>SGPA</th>
                            <th>CGPA</th>
                            <th>Status</th>
                            <th>Credits</th>
                          </tr>
                        </thead>
                        {student.results.map((grade, index) => (
                          <>
                            <tbody key={index}>
                              <tr>
                                <th
                                  style={{
                                    borderBottom: "none",
                                    borderTop: index != 0 && "none",
                                  }}
                                >
                                  {index == 0 && grade.student.rollNo}
                                </th>
                                <td style={{ textAlign: "center" }}>
                                  {grade.subject.subjectCode}
                                </td>
                                <td>{grade.subject.name}</td>
                                <td style={{ textAlign: "center" }}>
                                  {grade.grade}
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    borderBottom: "none",
                                  }}
                                >
                                  {index == 0 && student.sgpa}
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    borderBottom: "none",
                                  }}
                                >
                                  {index == 0 && student.cgpa}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {grade.passed ? "P" : "F"}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {grade.credits}
                                </td>
                              </tr>
                            </tbody>
                          </>
                        ))}
                        <tr>
                          <th>{student.results[0].student.rollNo}</th>
                          <th></th>
                          <th>Total</th>
                          <th>{}</th>
                          <th style={{ textAlign: "center" }}>
                            {student.sgpa ?? "-"}
                          </th>
                          <th style={{ textAlign: "center" }}>
                            {student.cgpa ?? "-"}
                          </th>
                          <th>{}</th>
                          <th style={{ textAlign: "center" }}>
                            {student.results.reduce(
                              (sum, item) => sum + parseFloat(item.credits),
                              0
                            )}
                          </th>
                        </tr>
                      </table>
                    ))}
                  </div>
                </StyledContainer>
              </div>
            ))}
          </>
        );
      })}
    </div>
  );
}
