import Spinner from "components/Spinner";
import _ from "lodash";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import axios from "utils/axios";

const StyledContainer = styled.div`
  padding-top: 1cm;
  padding-left: 0.5cm;
  padding-right: 0.5cm;
  color: black;
  page-break-after: always;
  header .title {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }

  header .caption,
  header .address {
    text-align: center;
  }
  table.attendance thead tr {
    background-color: #efefef;
  }

  header .exam-details {
    text-transform: uppercase;
    text-align: center;
  }

  table.attendance {
    width: 100%;
  }

  table.attendance td,
  table.attendance th {
    border: 1px solid black;
    padding: 8px 4px;
    text-align: center;
  }

  footer {
    margin-top: 1rem;
    padding: 8px;
    /* border: 1px solid black; */
  }
  .align-center {
    text-align: center;
  }

  @media print {
    page-break-after: always;
    margin-left: 0.5cm;
    margin-right: 0.5cm;
  }
`;

export default function SectionWiseAnalysisReport() {
  const match: any = useRouteMatch<any>();

  const { isLoading, data } = useQuery("sectionwise-analysis-report", () => {
    const params = new URLSearchParams(window.location.search);
    return axios
      .get(`/result-analysis/sectionwise-analysis-report`, {
        params: {
          monthYear: params.get("monthYear"),
          courseId: params.get("courseId"),
          semNo: params.get("semNo"),
          regulation: params.get("regulation"),
        },
      })
      .then((res) => res.data);
  });

  useEffect(() => {
    if (data) {
      document.title = data.examName;
    }
  }, [data]);

  if (isLoading) {
    return <Spinner />;
  }

  console.log(_.chunk(data.data, 25));
  let chunkLength = 26;
  let chunks = _.chunk(data.data, chunkLength);

  return (
    <div
      style={{
        background: "white",
        width: "25cm",
        minHeight: "33cm",
        borderRadius: "5px",
        margin: "auto",
      }}
    >
      {/* {chunks.map((chunk, chunksIndex) => (
        <div key={chunksIndex}> */}
      <StyledContainer>
        <Header
          schoolName={data.school.name}
          logo={data.school.logoSquare}
          address={data.school.address}
          course={data?.course?.courseName}
          semester={data?.semester?.name}
          monthYear={data?.monthYear}
          regulation={data?.regulation}
          title={data?.title}
          examName={data.examName}
        />
        <div style={{ padding: "0px 24px 0px 24px" }}>
          <div style={{ paddingBottom: 12 }}>
            {/* <div style={{ padding: "10px 0px 10px 0px", fontWeight: "bold" }}>
                Branch: {branch.branchCode}
              </div> */}
            <table className="attendance">
              <colgroup>
                <col style={{ width: "15%" }} />
                <col style={{ width: "12%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "12%" }} />
                <col style={{ width: "12%" }} />
                <col style={{ width: "12%" }} />
              </colgroup>
              <thead>
                <tr>
                  <th>Program</th>
                  <th>Appeared</th>
                  <th>Passed</th>
                  <th>Failed</th>
                  <th>Pass %</th>
                  <th>Fail %</th>
                </tr>
              </thead>
              <tbody>
                {data.branchwise.map((section, sectionIndex) => (
                  <tr>
                    <td>{section.branch}</td>
                    <td>{section.appeared}</td>
                    <td>{section.passed}</td>
                    <td>{section.failed}</td>
                    <td>{section.passPerc}</td>
                    <td>{section.failPerc}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </StyledContainer>
      <StyledContainer>
        <Header
          schoolName={data.school.name}
          logo={data.school.logoSquare}
          address={data.school.address}
          course={data?.course?.courseName}
          semester={data?.semester?.name}
          monthYear={data?.monthYear}
          regulation={data?.regulation}
          title={data?.title}
          examName={data.examName}
        />
        <div style={{ padding: "0px 24px 0px 24px" }}>
          {data.subjectBranchwise.map((branch, branchIndex) => (
            <div style={{ paddingBottom: 12 }}>
              <div style={{ padding: "10px 0px 10px 0px", fontWeight: "bold" }}>
                Program: {branch.branchCode}
              </div>
              <table className="attendance">
                <colgroup>
                  <col style={{ width: "12%" }} />
                  <col style={{ width: "1%" }} />
                  <col style={{ width: "2%" }} />
                  <col style={{ width: "2%" }} />
                  <col style={{ width: "2%" }} />
                  <col style={{ width: "2%" }} />
                </colgroup>
                <thead>
                  <tr>
                    <th>Course</th>
                    <th>Appeared</th>
                    <th>Passed</th>
                    <th>Failed</th>
                    <th>Pass %</th>
                    <th>Fail %</th>
                  </tr>
                </thead>
                <tbody>
                  {branch.subjects.map((section, sectionIndex) => (
                    <tr>
                      <td style={{ textAlign: "left" }}>{section.subject}</td>
                      <td>{section.appeared}</td>
                      <td>{section.passed}</td>
                      <td>{section.failed}</td>
                      <td>{section.passPerc}</td>
                      <td>{section.failPerc}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
        </div>
      </StyledContainer>
      <StyledContainer>
        <Header
          schoolName={data.school.name}
          logo={data.school.logoSquare}
          address={data.school.address}
          course={data?.course?.courseName}
          semester={data?.semester?.name}
          monthYear={data?.monthYear}
          regulation={data?.regulation}
          title={data?.title}
          examName={data.examName}
        />
        <div style={{ padding: "0px 24px 0px 24px" }}>
          {data.sectionSubjectwise.map((branch, branchIndex) => (
            <div style={{ paddingBottom: 12 }}>
              <div style={{ padding: "10px 0px 10px 0px", fontWeight: "bold" }}>
                Program: {branch.branchCode}
              </div>
              {Object.keys(branch?.sections)?.map((section, sectionIndex) => (
                <div style={{ paddingBottom: 16 }}>
                  <div
                    style={{ padding: "10px 0px 10px 0px", fontWeight: "bold" }}
                  >
                    Section - {section}
                  </div>
                  <table className="attendance" key={sectionIndex}>
                    <colgroup>
                      <col style={{ width: "1%" }} />
                      <col style={{ width: "12%" }} />
                      <col style={{ width: "2%" }} />
                      <col style={{ width: "2%" }} />
                      <col style={{ width: "2%" }} />
                      <col style={{ width: "2%" }} />
                      <col style={{ width: "2%" }} />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>Section</th>
                        <th>Course</th>
                        <th>Appeared</th>
                        <th>Passed</th>
                        <th>Failed</th>
                        <th>Pass %</th>
                        <th>Fail %</th>
                      </tr>
                    </thead>
                    <tbody>
                      {branch.sections[section]?.subjects.map(
                        (subject, subjectIndex) => (
                          <tr key={subjectIndex}>
                            <td>SEC - {section}</td>
                            <td style={{ textAlign: "left" }}>
                              {subject.subject}
                            </td>
                            <td>{subject.appeared}</td>
                            <td>{subject.passed}</td>
                            <td>{subject.failed}</td>
                            <td>{subject.passPerc}</td>
                            <td>{subject.failPerc}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              ))}
            </div>
          ))}
        </div>
      </StyledContainer>
    </div>
    //   ))}
    // </div>
  );
}

interface HeaderProps {
  logo: any;
  schoolName: string;
  address: string;
  title: string;
  course: string;
  semester: string;
  monthYear: string;
  regulation: string;
  examName?: string;
}

export function Header(props: HeaderProps) {
  return (
    <header>
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <img src={props.logo} style={{ height: "2rem" }} />
            </div>
            <div style={{ marginLeft: "0.5rem", marginRight: "0.5rem" }}>
              <div style={{ fontSize: "20px" }}>
                <b>{props.schoolName}</b>
              </div>
            </div>
          </div>
          <div className="address">{props.address}</div>
        </div>
        <hr />
        <div className="address">
          <b>
            <u>{props.title}</u>
          </b>
        </div>
        <div
          style={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: 16,
            textDecoration: "underline",
            padding: "2px 0px 2px 0px",
          }}
        >
          Result Analysis
        </div>
        <div
          style={{
            textAlign: "center",
            fontWeight: "bold",
            padding: "8px 0px 8px 0px",
          }}
        >
          {props.examName}
        </div>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: "8px",
            marginBottom: "16px",
          }}
        >
          <div>
            <b>Course:</b> {props.course}
          </div>

          <div>
            <b>Semester:</b> {props.semester}
          </div>

          <div>
            <b>Month & Year:</b> {props.monthYear}
          </div>

          <div>
            <b>Curriculum:</b> {props.regulation}
          </div>
        </div> */}
      </div>
    </header>
  );
}
