import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ofType } from 'redux-observable';
import { switchMap, map } from 'rxjs/operators';
import axios from "utils/axios";
import _ from 'lodash';
import * as XLSX from 'xlsx';
import moment from 'moment';

interface FeeReportState {
  data: any,
  error: any,
  loading: boolean,
  fromDate: string,
  toDate: string,
  reportData: Array<any>,
  disableDownload: boolean
}

const initialState: FeeReportState = {
  data: null,
  error: null,
  loading: false,
  fromDate: null,
  toDate: null,
  reportData: [],
  disableDownload: true
};

interface FetchReportPayload {
  fromDate: string;
  toDate: string;
}

function isNumber(n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }

const feeReportSlice = createSlice({
  name: 'feeReport',
  initialState: initialState,
  reducers: {
    fetchReport(state, action: PayloadAction<FetchReportPayload>) {
      state.loading = true;
    },
    fetchingReport(state) {
      state.loading = true;
      state.error = null;
    },
    fetchedReport(state, action) {
      state.loading = false;
      state.error = null;
      state.data = action.payload.data;
      state.reportData = action.payload.data.receipts;
      state.disableDownload = false;
    },
    onSearch(state, action) {
      const query = action.payload.query.trim();

      if (query == '') {
        state.reportData = state.data.receipts;
        return;
      }

      state.reportData = state.data.receipts.filter(item => {
        if (isNumber(query)) {
          return `${item.id}`.startsWith(query);
        } else {
          return item.fullName.toLowerCase().startsWith(query.toLowerCase());
        }
      });
    },
    exportToExcel(state) {
      if (!state.data) {
        alert('Please fetch the report first');
        return;
      }

      const excelData = state.data.receipts.map((item, index) => ({
        'S.No': index + 1,
        'Receipt No.': item.receiptNo,
        'AU Hall Ticket No': item.rollNo,
        'EAMCET HT': item.interHallTicketNumber,
        'Branch': item.branchCode,
        'Student Name': item.fullName,
        'Paid Amount': item.paidAmount,
        'Due Amount': item.dueAmount,
        'Mode of Payment': item.paymentMethod,
        'Paid By': item.paidBy,
        'Collected By': item.receivedBy,
        'Date': moment(item.dateTime).format('YYYY-MM-DD hh:mm:ss A'),
        'Remarks': item.notes
      }))

      const fileName = `Fee report ${state.fromDate} - ${state.toDate}.xlsx`;
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "test");
      XLSX.writeFile(wb, fileName);
    }
  }
})

export function fetchReportEpic(action$) {
  return action$.pipe(
    ofType(fetchReport.type),
    switchMap(async (action: PayloadAction<FetchReportPayload>) => {
      const res = await axios.get(`/fee-receipts`, {
        params: {
          fromDate: action.payload.fromDate,
          toDate: action.payload.toDate
        }
      });

      return {
        fromDate: action.payload.fromDate,
        toDate: action.payload.toDate,
        data: res.data
      }
    }),
    map(fetchedReport)
  );
}

export const {
  fetchedReport, fetchingReport, fetchReport, onSearch, exportToExcel
} = feeReportSlice.actions;

export default feeReportSlice.reducer;