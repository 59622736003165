import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ofType} from 'redux-observable';
import axios from 'utils/axios';
import {switchMap, map} from 'rxjs/operators';
import serverErrorDialog from "../../components/serverErrorDialog";

export interface FeePaymentsState {
  data: any,
  error: any,
  loading: boolean,
  hallTicket: string,
  examType: string
  examFeeDetails: any,
  examFeePaymentList: any,
}

const initialState: FeePaymentsState = {
  hallTicket: '',
  data: null,
  error: null,
  examType: '',
  loading: true,
  examFeeDetails: null,
  examFeePaymentList: null,
}

const examFeePaymentsSlice = createSlice({
  name: 'feePayments',
  initialState,
  reducers: {
    fetchExamFeePaymentDetails(state, action) {
      state.loading = true;
      state.hallTicket = action.payload.hallTicket;
      state.examType = action.payload.examType
    },
    fetchedExamFeePaymentDetails(state, action) {
      state.loading = false;
      state.data = action.payload.data;
      state.examFeeDetails = action.payload.examFeeDetails;
      // state.examFeePaymentList = action.payload.examFeePaymentList;
    },
  },
});

export const {
  fetchExamFeePaymentDetails, fetchedExamFeePaymentDetails
} = examFeePaymentsSlice.actions

export function fetchStudentExamFeePaymentDetailsEpic(action$) {
  return action$.pipe(
    ofType(fetchExamFeePaymentDetails.type),
    switchMap(async (action: any) => {
      try {
        let examFeePaymentDetailsRes = await axios.get(`/exam-fee-payment/regular-fee-details`, {
          params: {
            rollNo: action.payload.hallTicket,
            examType: action.payload.examType
          }
        });
        
        // let examFeePaymentsListRes = await axios.get(`/exam-fee-payment/list`, {
        //   params: {
        //     hallTicket: action.payload.hallTicket
        //   }
        // })
        
        return {
          data: examFeePaymentDetailsRes.data,
          examFeeDetails: examFeePaymentDetailsRes.data,
          // examFeePaymentList: examFeePaymentsListRes.data,
          loading: false,
          error: null
        };
      } catch (err) {
        console.log(err);
        serverErrorDialog({error: err})
        return {
          data: null,
          examFeeDetails: null
        }
      }
    }),
    map(fetchedExamFeePaymentDetails)
  );
}

export default examFeePaymentsSlice.reducer
