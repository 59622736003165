import { Store } from "pullstate";

export interface IPermissions {
  can_create_admission: boolean;
  can_create_role: boolean;
  can_create_user: boolean;
  can_delete_admission: boolean;
  can_delete_role: boolean;
  can_delete_user: boolean;
  can_edit_admission: boolean;
  can_edit_role: boolean;
  can_edit_user: boolean;
  can_manage_admissions: boolean;
  can_manage_roles: boolean;
  can_manage_users: boolean;
  can_view_admissions: boolean;
  can_view_roles: boolean;
  can_view_users: boolean;
  can_view_students: boolean;
  can_view_exams: boolean;
  can_view_classrooms: boolean;
  can_view_clubs: boolean;
  can_view_events: boolean;
  can_view_students_feed: boolean;
  can_print_application_form: boolean;
  can_pay_tution_fee: boolean;
  can_allot_tution_fee: boolean;
  can_manage_scholarships: boolean;
  can_create_scholarship: boolean;
  can_edit_scholarship: boolean;
  can_delete_scholarship: boolean;
  can_manage_exams: boolean;
  can_create_exam: boolean;
  can_create_exam_timetable: boolean;
  can_manage_fees: boolean;
  can_create_fee_structures: boolean;
  can_pay_exam_fee: boolean;
  can_view_fee_structures: boolean;
  can_view_fee_reports: boolean;
  can_manage_curriculums: boolean;
  can_view_curriculums: boolean;
  can_manage_programs: boolean;
  can_create_program: boolean;
  can_view_programs: boolean;
  can_edit_program: boolean;
  can_manage_courses: boolean;
  can_view_courses: boolean;
  can_create_course: boolean;
  can_edit_course: boolean;
  can_manage_subjects: boolean;
  can_view_subjects: boolean;
  can_create_subject: boolean;
  can_edit_subject: boolean;
  can_process_results: boolean;
  can_generate_memos: boolean;
  can_print_memos: boolean;
  can_manage_marks_divisions: boolean;
  can_create_marks_division: boolean;
  can_edit_marks_division: boolean;
  can_manage_assessment_rules: boolean;
  can_create_assessment_rule: boolean;
  can_edit_assessment_rule: boolean;
  can_delete_assessment_rule: boolean;
  can_update_internal_marks_from_lms: boolean;
  can_update_internal_marks_from_ums: boolean;
  can_update_theory_external_marks: boolean;
  can_update_non_theory_external_marks: boolean;
  can_create_omrs: boolean;
}

interface IPermissionsStore {
  permissions: IPermissions;
}

export const PermissionsStore = new Store<IPermissionsStore>({
  permissions: null,
});
