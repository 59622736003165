import {createSlice} from "@reduxjs/toolkit";
import {ofType} from 'redux-observable';
import {map, switchMap} from 'rxjs/operators';
import axios from "utils/axios";
import _ from 'lodash';

const initialState = {
  loading: true,
  allBatches: [],
  allExams: [],
  batches: [],
  curriculums: [],
  sessions: [],
  sessionsByTime: {},
  selected: {
    groupName: '',
    batches: [],
    exams: [],
    curriculums: [],
    batchSemesters: [],
    sessionsPerDay: null,
    sessionTimings: [],
    examType: null,
    examMonth: null,
    examYear: null,
    examStartDate:null,
    intOrExt:true
  },
}

const slice = createSlice({
  name: 'createExams',
  initialState: initialState,
  reducers: {
    fetchData(state) {
      state.loading = true;
    },
    fetchedData(state, action) {
      state.loading = false;
      state.curriculums = action.payload.curriculums;
      state.batches = action.payload.batches;
    },
    updateGroupName(state, action) {
      state.selected.groupName = action.payload;
    },
    selectCurriculums(state, action) {
      state.selected.curriculums = action.payload.curriculums;
      state.selected.batches = [];
    },
    selectBatches(state, action) {
      state.selected.batches = action.payload;
    },
    selectBatchSemester(state, action) {
      if (action.payload.checked) {
        state.selected.batchSemesters.push(action.payload.value);
      } else {
        state.selected.batchSemesters.filter(item => {
          return item === action.payload.value
        })
      }
    },
    selectSessionsPerDay(state, action) {
      state.selected.sessionsPerDay = action.payload;
      state.selected.sessionTimings = [];
      for (let i = 0; i < state.selected.sessionsPerDay; i++) {
        state.selected.sessionTimings.push({
          fromTime: null,
          toTime: null
        });
      }
    },
    selectExamsStartDate(state, action) {
      state.selected.examStartDate = action.payload;
    },
    selectIntOrExt(state, action) {
      state.selected.intOrExt = action.payload;
    },
    selectExamType(state, action) {
      state.selected.examType = action.payload;
    },
    selectExamMonth(state, action) {
      state.selected.examMonth = action.payload;
    },
    selectExamYear(state, action) {
      state.selected.examYear = action.payload;
    },
    selectRange(state, action) {
      state.selected.sessionTimings[action.payload.index].fromTime = action.payload.fromTime;
      state.selected.sessionTimings[action.payload.index].toTime = action.payload.toTime;
    },
  }
});


export function fetchDataEpic(action$) {
  return action$.pipe(
    ofType(fetchData),
    switchMap(async (action: any) => {
      const curriculums = await axios.get(`/curriculums`);
      const batches = await axios.get(`/batch-semesters/available-semesters-for-exams`);
      
      return {
        curriculums: curriculums.data.sort((a, b) => b.year - a.year),
        batches: batches.data.batchSemesters,
      };
    }),
    map(fetchedData),
  );
}

const {fetchedData} = slice.actions;

export const {
  fetchData, selectCurriculums, selectBatches, selectBatchSemester,
  selectSessionsPerDay, selectExamMonth, selectExamType, selectExamYear,
  selectRange,selectExamsStartDate,selectIntOrExt, updateGroupName
} = slice.actions

export default slice.reducer;

