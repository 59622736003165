import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  type: '',
  tags: null,
  content: '',
  text: '',
  link: '',
  resources: [],
  document: null,
  loading: false,
  error: null,
  data: null
}

const createPostSlice = createSlice({
  name: 'createPost',
  initialState: initialState,
  reducers: {
    onTextChange(state, action) {
      state.content = action.payload.content
    },
    onLinkChange(state, action) {
      state.link = action.payload.link
    },
    changeTags(state, action) {
      state.tags = action.payload.tags
    },
    changeType(state, action) {
      state.type = action.payload.type
      state.resources = []
    },
    updateResource(state, action) {
      state.resources = [action.payload.key];
    },
    clearPostState(state, action) {
      state.loading = false;
      state.content = null;
      state.type = null;
      state.tags = [];
      state.link = null;
      state.resources = [];
    }
  }
})



export const {
  onTextChange, changeTags,
  updateResource, clearPostState, changeType,
  onLinkChange
} = createPostSlice.actions;

export default createPostSlice.reducer;