import {createSlice} from "@reduxjs/toolkit";
import axios from "utils/axios";
import {ofType} from 'redux-observable';
import {switchMap, map} from 'rxjs/operators';

interface BatchSemestersState {
  loading: boolean,
  data: BatchSemesters,
  error: any,
  batch: Batch,
  semesters: BatchSemester[],
  selectedSemesterIndex: number,
  showActiveButton: boolean,
  semesterDetails: object
  semesterExams: any,
  electives: any
  internalMarks: []
}

interface BatchSemesters {
  batch: Batch;
  j: BatchSemester[];
}

export interface BatchSemester {
  id: number;
  batchId: number;
  semesterId: number;
  status: string;
  createdAt: string;
  updatedAt: string;
  semester: Semester;
  batch: MasterBatch;
}

export interface Semester {
  id: number;
  courseId: number;
  year: number;
  semester: string;
  completion: boolean;
  isAnnualExam: boolean;
  semPrintName: string;
  semNo: number;
}


export interface MasterBatch {
  id: number;
  name: string;
  currentSemesterId: number;
  curriculumId: number;
  validity: number;
  status: string;
  createdAt: string;
  updatedAt: string;
}

export interface Batch {
  id: number;
  name: string;
  currentSemesterId: number;
  curriculumId: number;
  validity: number;
  status: string;
  createdAt: string;
  updatedAt: string;
  curriculum: Curriculum;
}

export interface Curriculum {
  id: number;
  name: string;
  year: number;
  programId: number;
  totalCredits: number;
  maxSubjectsExcluded: number;
  maxCreditsExcluded: number;
  gracePercentage: string;
  finalGraceSubjects: number;
  finalGraceMarks: number;
  program: Program;
}

export interface Program {
  id: number;
  courseId: number;
  branchCode: string;
  branchName: string;
  seats: number;
  branchId: string;
  courseName: string;
  branchDisplay: string;
}

const initialState: BatchSemestersState = {
  loading: true,
  data: null,
  error: null,
  batch: null,
  semesters: [],
  selectedSemesterIndex: null,
  showActiveButton: false,
  semesterDetails: {},
  semesterExams: [],
  internalMarks: [],
  electives: {}
}


const batchSemesterSlice = createSlice({
  name: 'batch-semesters',
  initialState: initialState,
  reducers: {
    fetchBatchSemesters(state, action: any) {
      state.loading = true;
    },
    fetchedBatchSemesters(state, action: any) {
      state.loading = false;
      state.data = action.payload.data;
      state.batch = action.payload.data.batch;
      state.semesters = action.payload.data.batchSemesters;
    },
    fetchedSemesterElectives(state, action: any) {
      state.electives = action.payload.data;
    },
    fetchedSemesterDetails(state, action: any) {
      state.loading = false;
      state.semesterDetails = action.payload.data
    },
    fetchedSemesterExams(state, action: any) {
      state.loading = false;
      state.semesterExams = action.payload.exams;
      state.internalMarks = action.payload.internalMarks;
    },
    selectSemester(state, action: any) {
      state.selectedSemesterIndex = action.payload.index
    },
    activateSemester(state, action: any) {
      state.semesters[state.selectedSemesterIndex]['status'] = 'active';
    },
  }
});

export function fetchBatchSemestersEpic(action$, state$) {
  return action$.pipe(
    ofType(fetchBatchSemesters.type),
    switchMap(async (action: any) => {
      let res = await axios.get(`/batch-semesters?batchId=${action.payload.batchId}`)
      return {
        data: res.data,
      }
    }),
    map(fetchedBatchSemesters),
  );
}

export function selectSemesterEpic(action$, state$) {
  return action$.pipe(
    ofType(fetchedBatchSemesters.type),
    switchMap(async (action: any) => {
      let semesterIndex = state$.value.batchSemesters.selectedSemesterIndex || 0
      return {index: semesterIndex}
    }),
    map(selectSemester),
  );
}


export function fetchSemesterDetailsEpic(action$, state$) {
  return action$.pipe(
    ofType(selectSemester.type),
    switchMap(async (action: any) => {
      if(!state$.value.batchSemesters.semesters.length) {
        return {}
      }
      let semesterId = state$.value.batchSemesters.semesters[state$.value.batchSemesters.selectedSemesterIndex].id
      let res = await axios.get(`/batch-semesters/${semesterId}/details`)
      return {
        data: res.data,
      }
    }),
    map(fetchedSemesterDetails),
  );
}


export function fetchSemesterExamsEpic(action$, state$) {
  return action$.pipe(
    ofType(selectSemester.type),
    switchMap(async (action: any) => {
      if(!state$.value.batchSemesters.semesters.length) {
        return []
      }
      let semesterId = state$.value.batchSemesters.semesters[state$.value.batchSemesters.selectedSemesterIndex].id
      let exams = await axios.get(`/batch-semesters/${semesterId}/exams`)
      let internalMarks = await axios.get(`/batch-semesters/${semesterId}/internal-marks`)
      return {
        exams: exams.data,
        internalMarks: internalMarks.data
      }
    }),
    map(fetchedSemesterExams),
  );
}

export function fetchSemesterElectivesEpic(action$, state$) {
  return action$.pipe(
    ofType(selectSemester.type),
    switchMap(async (action: any) => {
      if(!state$.value.batchSemesters.semesters.length) {
        return []
      }
      let semesterId = state$.value.batchSemesters.semesters[state$.value.batchSemesters.selectedSemesterIndex].id
      let res = await axios.get(`/elective-subjects?type=elective&batchSemesterId=${semesterId}`)
      return {
        data: res.data,
      }
    }),
    map(fetchedSemesterElectives),
  );
}


const {
  fetchedBatchSemesters, fetchedSemesterDetails,
  fetchedSemesterExams, fetchedSemesterElectives
} = batchSemesterSlice.actions

export const {
  fetchBatchSemesters, selectSemester, activateSemester
} = batchSemesterSlice.actions

export default batchSemesterSlice.reducer;