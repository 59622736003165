import { createSlice } from "@reduxjs/toolkit";
import axios from "utils/axios";
import { ofType } from 'redux-observable';
import { switchMap, map } from 'rxjs/operators';

const initialState = {
  loading: true,
  data: [],
  error: null,
  assessments: [],
  showModal: false,
}

const subjectAssessmentsSlice = createSlice({
  name: 'subjectAssessments',
  initialState: initialState,
  reducers: {
    fetchAssessments(state, action: any) {
      state.loading = true;
    },
    fetchedAssessments(state, action: any) {
      state.loading = false;
      state.data = action.payload.data;
      state.assessments = action.payload.data.map(item => ({
        ...item,
        isEditing: false
      }));
    },
    showModal(state) {
      state.showModal = true;
    },
    hideModal(state) {
      state.showModal = false;
    },
    updateValue(state, action: any) {
      let index = action.payload.index;
      let key = action.payload.key;
      let value = action.payload.value;

      if (key == 'isWrittenExam' && value == true) {
        state.assessments[index]['name'] = 'Internal';
      }

      state.assessments[index][key] = value;
    },
    addAssessment(state) {
      state.assessments = state.assessments.filter(f => f);
      let id = state.assessments.length + 1;
      state.assessments = [
        ...state.assessments,
        {
          "name": "New Assessment " + id,
          "assessmentTypeId": 1,
          "evaluationTypeId": 1,
          "curriculumSubjectId": 1,
          "weightage": 5,
          "maxMarks": 5,
          "passPercentage": 30,
          "isWrittenExam": false,
          "assessmentsConducted": 10,
          "assessmentsGraded": 5,
          "scoreCalculationType": "Sum",
          "isFeeApplicable": false,
          "hasSupplementary": false,
          "isNew": true,
          "isEditing": true
        }
      ]
    },
    removeAssessment(state, action) {
      delete state.assessments[action.payload.index];
    },
    editAssessment(state, action) {
      state.assessments[action.payload.index].isEditing = true;
    },
    updateAssessment(state, action) {
      state.assessments[action.payload.index].isEditing = false;
    },
    onCancel(state, action) {
      state.assessments[action.payload.index].isEditing = false;
    },
    saveAssessment(state, action) {
      state.assessments[action.payload.index] = action.payload.data;
    },
  }
});

export function fetchSubjectAssessmentsEpic(action$, state$) {
  return action$.pipe(
    ofType(fetchAssessments.type),
    switchMap(async (action: any) => {
      const res = await axios.get(`/subject-assessments/?subjectId=${action.payload.subjectId}`);
      return {
        data: res.data,
      }
    }),
    map(fetchedAssessments),
  );
}

const { fetchedAssessments } = subjectAssessmentsSlice.actions;

export const {
  fetchAssessments, saveAssessment, updateAssessment,
  showModal, hideModal, updateValue, onCancel,
  addAssessment, removeAssessment, editAssessment
} = subjectAssessmentsSlice.actions
export default subjectAssessmentsSlice.reducer;