import { createSlice } from "@reduxjs/toolkit";
import axios from "utils/axios";
import { ofType } from "redux-observable";
import { switchMap, map } from "rxjs/operators";

const initialState = {
  loading: true,
  data: null,
  error: null,
  classrooms: null,
};

const classroomsSlice = createSlice({
  name: "classrooms",
  initialState: initialState,
  reducers: {
    fetchClassrooms(state, action: any) {
      state.loading = true;
    },
    fetchedClassrooms(state, action: any) {
      state.loading = false;
      state.data = action.payload.data;
      state.classrooms = action.payload.data;
    },
  },
});

export function fetchClassroomsEpic(action$, state$) {
  return action$.pipe(
    ofType(fetchClassrooms.type),
    switchMap(async (action: any) => {
      const res = await axios.get(
        `/classrooms/batch?batch=${action.payload.batch}&courseId=${action.payload.courseId}&branchCode=${action.payload.branchCode}`
      );
      return {
        data: res.data,
      };
    }),
    map(fetchedClassrooms)
  );
}

export const { fetchClassrooms } = classroomsSlice.actions;

const { fetchedClassrooms } = classroomsSlice.actions;

export default classroomsSlice.reducer;
