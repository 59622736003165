import { Button, Col, Form, Row, Modal } from 'antd'
import Input from 'antd/es/input/Input'
import serverErrorDialog from 'components/serverErrorDialog'
import validateMessages from 'constants/validateMessages'
import React from 'react'
import axios from 'utils/axios'
import { useHistory } from 'react-router-dom'
import { AssetsStore } from 'shared-state/AssetsStore'

function useQuery() {
  return new URLSearchParams(window.location.search);
}

export default function ResetPassword() {
  let query = useQuery()
  let history = useHistory()
  const assets = AssetsStore.useState(s => s);

  function handleSubmit(values) {
    axios.post(`/auth/reset-password`, {
      token: query.get('token'),
      newPassword: values.newPassword,
      confirmNewPassword: values.confirmNewPassword
    }).then(res => {
      Modal.success({
        content: 'Your password has been changed successfully',
        okText: 'Login',
        onOk: () => {
          localStorage.removeItem('token')
          window.location.href = '/'
        }
      })
    }).catch(err => {
      serverErrorDialog({error: err})
    })
  }

  return (
    <div style={{ padding: '24px' }}>
      <Row>
        <Col xl={6} lg={8} md={10}>
          <img src={assets.logo} style={{
            width: 'auto',
            height: '32px'
          }} />

          <br /><br />

          <h3>Reset your password</h3>
          <Form
            onFinish={handleSubmit}
            validateMessages={validateMessages}
            initialValues={{
              newPassword: '',
              confirmNewPassword: ''
            }}>
            <Form.Item name="newPassword" label="New Password" rules={[{ required: true }]}>
              <Input.Password />
            </Form.Item>

            <Form.Item name="confirmNewPassword" label="New Password" rules={[{ required: true }]}>
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Button htmlType="submit" block type="primary" size="large">Reset Password</Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  )
}
