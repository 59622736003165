import { Store } from 'pullstate'

export interface IUserStore {
  username: string;
  user: any;
  roles: any[]
}

export const UserStore = new Store({
  username: "",
  user: {},
  roles: [],
})