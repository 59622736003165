import { combineReducers } from "redux";
import { combineEpics } from "redux-observable";
import usersReducer, { fetchUserssEpic } from "./slices/usersSlice";
import feePaymentsReducer, {
  fetchStudentFeeDetailsEpic,
} from "./slices/feePaymentsSlice";
import admissionsReducer, {
  fetchAdmissionsEpic,
  hideFeeAllotModalEpic,
} from "./slices/admissionsSlice";
import feeReportReducer, { fetchReportEpic } from "./slices/feeReportSlice";
import studentsReducer, { fetchStudentsEpic } from "./slices/studentsSlice";
import createPostReducer from "./slices/createPostSlice";
import postsReducer, {
  fetchPostsEpic,
  fetchMorePostsEpic,
} from "./slices/postsSlice";
import eventsReducer, { fetchEventsEpic } from "./slices/eventsSlice";
import auditLogsReducer, { fetchAuditLogsEpic } from "./slices/auditLogsSlice";
import curriculumSubjectsReducer, {
  fetchCurriculumSubjectsEpic,
} from "./slices/curriculumSubjectsSlice";
import assessmentRulesReducer, {
  fetchAssessmentRulesEpic,
  updateAssessmentEpic,
} from "./slices/assessmentRulesSlice";
import awardOfClassReducer, {
  fetchAwardOfClassEpic,
  updateAwardOfClassEpic,
} from "./slices/awardOfClassSlice";
import promotionRulesReducer, {
  fetchPromotionRulesEpic,
  updatePromotionRulesEpic,
} from "./slices/promotionRulesSlice";
import timeTableReducer, { fetchTimeTableEpic } from "./slices/timeTableSlice";
import examFeePaymentReducer, {
  fetchStudentExamFeePaymentDetailsEpic,
} from "./slices/examFeePaymentSlice";
import studentFeesReducer, {
  fetchStudentFeesEpic,
} from "./slices/studentFeesSlice";
import assessmentsReducer, {
  fetchAssessmentsEpic,
} from "./slices/assessmentsSlice";
import subjectAssessmentsReducer, {
  fetchSubjectAssessmentsEpic,
} from "./slices/subjectAssessmentsSlice";
import batchSubjectsReducer, {
  fetchSubjectsEpic,
  fetchExamsEpic,
} from "./slices/batchSubjectsSlice";
import seatingArrangementSlice from "./slices/seatingArrangementSlice";
import examSessionsReducer, {
  fetchExamSessionsEpic,
} from "./slices/examSessionsSlice";
import classroomsReducer, {
  fetchClassroomsEpic,
} from "./slices/classroomSlice";
import evaluationRulesReducer, {
  fetchEvaluationRulesEpic,
} from "./slices/evaluationRulesSlice";
import curriculumsReducer, {
  fetchCurriculumsEpic,
} from "./slices/curriculumsSlice";
import bundlesReducer, {
  fetchBundlesEpic,
  editBundleEpic,
  addBundleEpic,
  addSheetEpic,
  saveBundleEpic,
  fetchEvaluatorsEpic,
  cancelEvaluationEpic,
} from "./slices/bundlesSlice";
import manualBundlesReducer, {
  epics as manualBundleEpics,
} from "./slices/manualBundlesSlice";
import batchSemestersReducer, {
  fetchBatchSemestersEpic,
  fetchSemesterDetailsEpic,
  selectSemesterEpic,
  fetchSemesterExamsEpic,
  fetchSemesterElectivesEpic,
} from "./slices/batchSemestersSlice";
import evaluationTypesReducer, {
  fetchEvaluationTypesEpic,
} from "./slices/evaluationTypesSlice";
import createExamsReducer, {
  fetchDataEpic as fetchExamFormData,
} from "./slices/createExamsSlice";
import createExamTimeTableReducer, {
  fetchDataEpic as fetchExamsData,
} from "./slices/createExamSlice";
import examTimetableReducer, {
  fetchExamTimetableEpic,
} from "./slices/examTimetableSlice";
import examFeeReducer, { fetchExamFeeEpic } from "./slices/examFeeSlice";
import subjectAttendanceReducer, {
  fetchSubjectAttendanceEpic,
} from "./slices/subjectAttendanceSlice";
import clubsReducer, { fetchClubsEpic } from "./slices/clubsSlice";
import careersReducer, {
  fetchCareersEpic,
  fetchRangeCareersEpic,
} from "./slices/careersSlice";
import phdReducer, { fetchPhdApplicationsEpic } from "./slices/phdSlice";
import invigilationsSlice, {
  fetchInvigilationsEpic,
} from "./slices/invigilationsSlice";

import studentExternalReducer, {
  fetchStudentExternalEpic,
  fetchNonTheoryExternalUpdateReasonsEpic
} from "./slices/studentExternalSlice";
import revaluationReducer, {
  fetchRevaluationStudentsEpic,
} from "./slices/revaluationSlice";
import theoryExternalMarksReducer, {
  fetchTheoryExternalEpic, fetchTheoryExternalUpdateReasonsEpic,
} from "./slices/theoryExternalMarksSlice";
import internalMarksReducer, {
  fetchInternalMarksReasonsEpic,
  fetchStudentInternalMarksEpic,
} from "./slices/internalMarksSlice";
export const rootEpic = combineEpics(
  fetchStudentFeeDetailsEpic,
  fetchAdmissionsEpic,
  fetchUserssEpic,
  hideFeeAllotModalEpic,
  fetchReportEpic,
  fetchStudentsEpic,
  fetchPostsEpic,
  fetchMorePostsEpic,
  fetchEventsEpic,
  fetchAuditLogsEpic,
  fetchCurriculumSubjectsEpic,
  fetchAssessmentRulesEpic,
  updateAssessmentEpic,
  fetchAwardOfClassEpic,
  updateAwardOfClassEpic,
  fetchPromotionRulesEpic,
  updatePromotionRulesEpic,
  fetchTimeTableEpic,
  fetchStudentExamFeePaymentDetailsEpic,
  fetchStudentFeesEpic,
  fetchAssessmentsEpic,
  fetchSubjectAssessmentsEpic,
  fetchSubjectsEpic,
  fetchExamSessionsEpic,
  fetchClassroomsEpic,
  fetchEvaluationRulesEpic,
  fetchCurriculumsEpic,
  fetchBundlesEpic,
  cancelEvaluationEpic,
  addBundleEpic,
  editBundleEpic,
  addSheetEpic,
  saveBundleEpic,
  manualBundleEpics.fetchBundlesEpic,
  manualBundleEpics.cancelEvaluationEpic,
  manualBundleEpics.addBundleEpic,
  manualBundleEpics.editBundleEpic,
  manualBundleEpics.addSheetEpic,
  manualBundleEpics.saveBundleEpic,
  manualBundleEpics.fetchEvaluatorsEpic,
  fetchEvaluatorsEpic,
  fetchBatchSemestersEpic,
  fetchEvaluationTypesEpic,
  fetchSemesterDetailsEpic,
  selectSemesterEpic,
  fetchSemesterExamsEpic,
  fetchSemesterElectivesEpic,
  fetchExamFormData,
  fetchExamsData,
  fetchExamTimetableEpic,
  fetchExamFeeEpic,
  fetchSubjectAttendanceEpic,
  fetchClubsEpic,
  fetchCareersEpic,
  fetchRangeCareersEpic,
  fetchPhdApplicationsEpic,
  fetchInvigilationsEpic,
  fetchExamsEpic,
  fetchStudentExternalEpic,
  fetchRevaluationStudentsEpic,
  fetchTheoryExternalEpic,
  fetchTheoryExternalUpdateReasonsEpic,
  fetchStudentInternalMarksEpic,
  fetchNonTheoryExternalUpdateReasonsEpic,
  fetchInternalMarksReasonsEpic
);

export const rootReducer = combineReducers({
  feePayments: feePaymentsReducer,
  admissions: admissionsReducer,
  users: usersReducer,
  feeReport: feeReportReducer,
  students: studentsReducer,
  createPost: createPostReducer,
  posts: postsReducer,
  events: eventsReducer,
  auditLogs: auditLogsReducer,
  curriculumSubjects: curriculumSubjectsReducer,
  assessmentRules: assessmentRulesReducer,
  awardOfClass: awardOfClassReducer,
  promotionRules: promotionRulesReducer,
  timeTable: timeTableReducer,
  examFeePayments: examFeePaymentReducer,
  studentFees: studentFeesReducer,
  assessments: assessmentsReducer,
  subjectAssessments: subjectAssessmentsReducer,
  batchSubjects: batchSubjectsReducer,
  seatingArrangement: seatingArrangementSlice,
  examSessions: examSessionsReducer,
  classrooms: classroomsReducer,
  evaluationRules: evaluationRulesReducer,
  curriculums: curriculumsReducer,
  bundles: bundlesReducer,
  manualBundles: manualBundlesReducer,
  batchSemesters: batchSemestersReducer,
  evaluationTypes: evaluationTypesReducer,
  createExams: createExamsReducer,
  createExamTimeTable: createExamTimeTableReducer,
  examTimetable: examTimetableReducer,
  examFee: examFeeReducer,
  subjectAttendance: subjectAttendanceReducer,
  clubs: clubsReducer,
  careers: careersReducer,
  phd: phdReducer,
  invigilations: invigilationsSlice,
  studentExternal: studentExternalReducer,
  revaluation: revaluationReducer,
  theoryExternalMarks: theoryExternalMarksReducer,
  internalMarks: internalMarksReducer,
});
