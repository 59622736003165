import {createSlice} from "@reduxjs/toolkit";
import {ofType} from 'redux-observable';
import {switchMap, map, filter} from 'rxjs/operators';
import axios from "utils/axios";
import * as XLSX from 'xlsx';
import serverErrorDialog from "../../components/serverErrorDialog";
import moment from "moment";

interface AdmissionsState {
  data: Array<any>
  error: any
  loading: boolean
  careers: Array<any>
  filters: any
}

const initialState: AdmissionsState = {
  data: [],
  error: null,
  loading: false,
  careers: [],
  filters: {
    programId: null,
    courseId: null,
    batch: null,
    quotaId: null,
    status: null,
    isStudent: null,
  }
};

const careersSlice = createSlice({
  name: 'careers',
  initialState,
  reducers: {
    fetchCareers(state) {
      state.loading = true
    },
    // fetchingAdmissions(state) {
    //   state.loading = true;
    // },
    fetchedCareers(state, action) {
      state.data = action.payload.data;
      state.careers = action.payload.data;
      state.loading = false;
      state.error = null;
    },
    onSearch(state, action) {
      let value = action.payload.value.toUpperCase();
      if (!value) {
        state.careers = state.data;
        return;
      }
      state.careers = state.data.filter(career => {
        if (career.email && `${career.email.toUpperCase() || ''}`.includes(value)) {
          return true
        }
        if (career.name && `${career.name.toUpperCase() || ''}`.includes(value)) {
          return true
        }
        if (career.position && `${career.position.toUpperCase() || ''}`.includes(value)) {
          return true
        }
        if (career.specialization && `${career.specialization.toUpperCase() || ''}`.includes(value.toUpperCase())) {
          return true
        }
      });
    },
    filterByDate(state, action) {},
    exportToExcel(state) {
      if (!state.careers) {
        alert('Please fetch the report first');
        return;
      }
      
      const excelData = state.careers.map((item, index) => ({
        'S.No': item.id,
        'Name': `${item.prefix}${item.name}`,
        'Email': item.email,
        'Mobile': item.mobile,
        'School': item.school,
        'Specialisation': item.specialization,
        'Applied Position': item.position,
      }))
      
      const fileName = `Careers.xlsx`;
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Careers");
      XLSX.writeFile(wb, fileName);
    }
    
  }
});

export const {
  fetchCareers,
  fetchedCareers,
  onSearch,
  exportToExcel,
  filterByDate
} = careersSlice.actions

export function fetchCareersEpic(action$, state$) {
  return action$.pipe(
    ofType(fetchCareers.type),
    switchMap(async (action: any) => {
      try {
        let res = await axios.get(`/careers`);
        
        let data = res.data.map((item, index) => ({
          sNo: index + 1,
          ...item
        }))
        
        return {
          data: data,
        };
      } catch (err) {
        serverErrorDialog({error: err})
        return {
          data: [],
        }
      }
    }),
    map(data => fetchedCareers(data))
  );
}

export function fetchRangeCareersEpic(action$, state$) {
  return action$.pipe(
    ofType(filterByDate.type),
    switchMap(async (action: any) => {
      try {
        let res = await axios.get(`/careers`, {
          params: {
            fromDate: action.payload.fromDate,
            toDate: action.payload.toDate
          }
        });
        
        let data = res.data.map((item, index) => ({
          sNo: index + 1,
          ...item
        }))
        
        return {
          data: data,
        };
      } catch (err) {
        serverErrorDialog({error: err})
        return {
          data: [],
        }
      }
    }),
    map(data => fetchedCareers(data))
  )
}


export default careersSlice.reducer;
