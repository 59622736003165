export const batchOptions = Array.from({ length: 10 }, (_, i) => {
  return `${2012 + i} - ${2012 + i + 1}`
}).reverse();


export const examGroupBatches = Array.from({ length: 10 }, (_, i) => {
  return `${2012 + i} - ${2012 + i + 1}`
});
export const currentYear = new Date().getFullYear()

const startYear = 2012

export const yearOptions = Array.from({ length: currentYear - startYear + 1 }, (_, i) => {
  return `${startYear + i}`
});

export const months = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
]

export const religionOptions = [
  'Hinduism',
  'Islam',
  'Christianity',
  'Sikhism',
  'Buddhism',
  'Jainism',
]

export const casteOptions = [
  'OC',
  'BC_A',
  'BC_B',
  'BC_C',
  'BC_D',
  'BC_E',
  'SC',
  'ST',
]

export const nationalityOptions = [
  'Indian',
  'Other',
]

export const examTimings = [
  '09:00 AM',
  '10:00 AM',
  '11:00 AM',
  '01:00 PM',
  '02:00 PM',
  '03:00 PM',
]
