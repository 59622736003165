import { createSlice } from "@reduxjs/toolkit";
import serverErrorDialog from "components/serverErrorDialog";
import { ofType } from "redux-observable";
import { map, switchMap } from "rxjs/operators";
import axios from "utils/axios";
const initialState = {
  loading: true,
  data: null,
  error: null,
  selectedSubject: null,
  selectedSubjectIndex: null,
  subjects: null,
  internalMarksLoading: false,
  externalMarksLoading: true,
  internalMarks: {},
  externalMarks: [],
  exams: [],
};

const batchSubjects = createSlice({
  name: "batchSubjects",
  initialState: initialState,
  reducers: {
    fetchSubjects(state, action: any) {
      state.loading = true;
    },
    fetchedSubjects(state, action: any) {
      state.loading = false;
      state.data = action.payload.data;
      state.subjects = action.payload.data;
      state.selectedSubjectIndex = null;
    },
    selectSubject(state, action: any) {
      // state.selectedSubject = action.payload.subject
      // state.internalMarksLoading = true;
      state.selectedSubjectIndex = action.payload.subjectIndex;
    },

    setInternalMarksLoading(state, action: any) {
      state.internalMarksLoading = action.payload;
    },
    fetchedInternalMarks(state, action: any) {
      state.internalMarksLoading = false;
      state.internalMarks = action.payload.internalMarks;
    },

    fetchedExams(state, action) {
      state.exams = action.payload.data;
    },

    clearSubjectsState(state, action: any) {
      state.loading = true;
      state.data = null;
      state.error = null;
      state.selectedSubject = null;
      state.subjects = null;
    },
    onSearch(state, action: any) {
      let value = action.payload.value;
      if (!value) {
        state.subjects = state.data;
        return;
      }
      state.subjects = state.data.filter((subject) => {
        return subject.name.toLowerCase().includes(value.toLowerCase());
      });
    },
  },
});

export function fetchSubjectsEpic(action$, state$) {
  return action$.pipe(
    ofType(fetchSubjects.type),
    switchMap(async (action: any) => {
      try {
        const res = await axios.get(`/subjects/reg`, {
          params: action.payload,
        });
        return {
          data: res.data,
        };
      } catch (e) {
        serverErrorDialog({ error: e });
        return {
          data: [],
        };
      }
    }),
    map(fetchedSubjects)
  );
}

export function fetchExamsEpic(action$, state$) {
  return action$.pipe(
    ofType(fetchSubjects.type),
    switchMap(async (action: any) => {
      // try {
      //   const res = await axios.get(`/marks`, {
      //     params: action.payload,
      //   });
      //   return {
      //     data: res.data,
      //   };
      // } catch (e) {
      //   return {
      //     data: [],
      //   };
      // }
      return []
    }),
    map(fetchedExams)
  );
}

export const {
  fetchSubjects,
  fetchedSubjects,
  selectSubject,
  clearSubjectsState,
  fetchedInternalMarks,
  onSearch,
  setInternalMarksLoading,
  fetchedExams,
} = batchSubjects.actions;

export default batchSubjects.reducer;
