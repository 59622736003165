import {createSlice} from "@reduxjs/toolkit";
import axios from "utils/axios";
import {ofType} from 'redux-observable';
import {switchMap, map} from 'rxjs/operators';

const initialState = {
  loading: true,
  data: null,
  error: null,
  items: [],
  links: {},
  meta: {},
}

const auditLogsSlice = createSlice({
  name: 'audit-logs',
  initialState: initialState,
  reducers: {
    fetchAuditLogs(state, action: any) {
    },
    fetchedAuditLogs(state, action: any) {
      state.loading = false;
      state.data = action.payload.data;
      state.items = action.payload.data.items;
      state.links = action.payload.data.links;
      state.meta = action.payload.data.meta;
    },
  }
});

export function fetchAuditLogsEpic(action$, state$) {
  return action$.pipe(
    ofType(fetchAuditLogs.type),
    switchMap(async (action: any) => {
      const res = await axios.get('/audit-log', {
        params: {
          page: action.payload.page, limit: action.payload.limit
        }
      });
      return {
        data: res.data,
      }
    }),
    map(fetchedAuditLogs),
  );
}


export const {fetchAuditLogs, fetchedAuditLogs} = auditLogsSlice.actions

export default auditLogsSlice.reducer;